import { ReportNestedSchema } from 'lib/model';
import ButtonTabs from 'modules/common/ButtonTabs';
import { Fragment, memo, useMemo, useState } from 'react';
import { ValueOption } from 'utils/interfaces';
import { Standing } from './interfaces';
import StandingsTable from './StandingsTable';
import { stringToValueOption } from 'utils/mappings';
import { BaseReportElementProps } from '../interfaces';

interface StandingsControllerProps extends BaseReportElementProps {
  team?: string;
  standings: Standing[];
  highlightedTeams: Record<string, Standing>;
}

const StandingsController = memo(function StandingsController({
  standings,
  highlightedTeams,
  team,
  report,
  element
}: StandingsControllerProps) {
  const data = useMemo(() => {
    const competitionStandings = standings.reduce((acc: Record<string, Standing[]>, curr) => {
      if (!acc[curr.competition_name]) {
        acc[curr.competition_name] = [curr];
      } else {
        acc[curr.competition_name].push(curr);
      }
      return acc;
    }, {});

    // sort
    for (const key of Object.keys(competitionStandings)) {
      competitionStandings[key].sort((a, b) => a.rank - b.rank);
    }

    return competitionStandings;
  }, [standings]);

  const tabsOptions = useMemo(() => Object.keys(data).map(stringToValueOption), [data]);
  const [selectedCompetition, setSelectedCompetition] = useState<ValueOption>(tabsOptions[0]);

  return (
    <div className="flex flex-col gap-6">
      {tabsOptions.length > 1 && (
        <div className="flex flex-col gap-3">
          {team && <span className="text-xs font-semibold">{team}</span>}
          <ButtonTabs options={tabsOptions} selected={selectedCompetition} setSelected={setSelectedCompetition} />
        </div>
      )}
      {Object.entries(data).map(
        ([competition, competitionData]) =>
          selectedCompetition.id === competition && (
            <Fragment key={competition}>
              {tabsOptions.length === 1 && (
                <span className="text-xs font-semibold">
                  {team ? `${team} - ` : ''}
                  {competition}
                </span>
              )}
              <StandingsTable
                standings={competitionData}
                highlightedTeams={highlightedTeams}
                report={report}
                element={element}
              />
            </Fragment>
          )
      )}
    </div>
  );
});

export default StandingsController;
