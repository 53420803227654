import { useQueryClient } from '@tanstack/react-query';
import useActiveProject from 'contexts/project/projectContext';
import {
  getGetCountriesQueryOptions,
  getGetPlayerPositionsQueryOptions,
  getGetPlayerSubPositionsQueryOptions
} from 'lib/search/search';

function usePrefetchScoutReportDropdowns() {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  queryClient.prefetchQuery({
    ...getGetPlayerPositionsQueryOptions(project.id!),
    queryKey: ['playerPositions', project.id],
    staleTime: Infinity
  });

  queryClient.prefetchQuery({
    ...getGetPlayerSubPositionsQueryOptions(project.id!),
    queryKey: ['playerSubPositions', project.id],
    staleTime: Infinity
  });

  queryClient.prefetchQuery({
    ...getGetCountriesQueryOptions(project.id!),
    queryKey: ['countries', project.id],
    staleTime: Infinity
  });
}

export default usePrefetchScoutReportDropdowns;
