/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  default as getAllTeamsMutator,
  default as getCompetitionsMutator,
  default as getCountriesMutator,
  default as getMatchesMutator,
  default as getPlayerMatchesMutator,
  default as getPlayerPositionsMutator,
  default as getPlayersMetricsMutator,
  default as getPlayersMutator,
  default as getPlayersSeasonMetricsMutator,
  default as getPlayerSubPositionsMutator,
  default as getTeamsMutator,
  default as searchPlayersMutator
} from '../../api/axios';
import type {
  GetAllTeams200Item,
  GetCompetitions200Item,
  GetCompetitionsParams,
  GetMatchesParams,
  GetPlayerMatchesParams,
  GetPlayersMetricsParams,
  GetPlayersParams,
  GetPlayersSeasonMetricsParams,
  GetTeamsParams,
  MatchesQuerySchema,
  MatchesSchema,
  PlayersQuerySchema,
  ReportMetricsSchema,
  SearchPlayersParams,
  SeasonMetricsSchema,
  SubPositionsSchema,
  TeamsQuerySchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Gets all of the competitions
 */
export const getCompetitions = (
  projectId: string,
  params?: GetCompetitionsParams,
  options?: SecondParameter<typeof getCompetitionsMutator>,
  signal?: AbortSignal
) => {
  return getCompetitionsMutator<GetCompetitions200Item[]>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/competitions`, method: 'GET', params, signal },
    options
  );
};

export const getGetCompetitionsQueryKey = (projectId: string, params?: GetCompetitionsParams) => {
  return [`/project/${projectId}/search/competitions`, ...(params ? [params] : [])] as const;
};

export const getGetCompetitionsQueryOptions = <TData = Awaited<ReturnType<typeof getCompetitions>>, TError = unknown>(
  projectId: string,
  params?: GetCompetitionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getCompetitionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompetitionsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompetitions>>> = ({ signal }) =>
    getCompetitions(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompetitions>>>;
export type GetCompetitionsQueryError = unknown;

/**
 * @summary Gets all of the competitions
 */
export const useGetCompetitions = <TData = Awaited<ReturnType<typeof getCompetitions>>, TError = unknown>(
  projectId: string,
  params?: GetCompetitionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getCompetitionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompetitionsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the countries
 */
export const getCountries = (
  projectId: string,
  options?: SecondParameter<typeof getCountriesMutator>,
  signal?: AbortSignal
) => {
  return getCountriesMutator<string[]>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/countries`, method: 'GET', signal },
    options
  );
};

export const getGetCountriesQueryKey = (projectId: string) => {
  return [`/project/${projectId}/search/countries`] as const;
};

export const getGetCountriesQueryOptions = <TData = Awaited<ReturnType<typeof getCountries>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>;
    request?: SecondParameter<typeof getCountriesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCountriesQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountries>>> = ({ signal }) =>
    getCountries(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCountries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>;
export type GetCountriesQueryError = unknown;

/**
 * @summary Gets all of the countries
 */
export const useGetCountries = <TData = Awaited<ReturnType<typeof getCountries>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>;
    request?: SecondParameter<typeof getCountriesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCountriesQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the teams
 */
export const getAllTeams = (
  projectId: string,
  options?: SecondParameter<typeof getAllTeamsMutator>,
  signal?: AbortSignal
) => {
  return getAllTeamsMutator<GetAllTeams200Item[]>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/get-all-teams`, method: 'GET', signal },
    options
  );
};

export const getGetAllTeamsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/search/get-all-teams`] as const;
};

export const getGetAllTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getAllTeams>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getAllTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTeamsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTeams>>> = ({ signal }) =>
    getAllTeams(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTeams>>>;
export type GetAllTeamsQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetAllTeams = <TData = Awaited<ReturnType<typeof getAllTeams>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getAllTeamsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllTeamsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the matches
 */
export const getMatches = (
  projectId: string,
  params?: GetMatchesParams,
  options?: SecondParameter<typeof getMatchesMutator>,
  signal?: AbortSignal
) => {
  return getMatchesMutator<MatchesQuerySchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/matches`, method: 'GET', params, signal },
    options
  );
};

export const getGetMatchesQueryKey = (projectId: string, params?: GetMatchesParams) => {
  return [`/project/${projectId}/search/matches`, ...(params ? [params] : [])] as const;
};

export const getGetMatchesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getMatches>>, GetMatchesParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetMatchesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getMatches>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getMatches>>,
        QueryKey,
        GetMatchesParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMatchesQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMatches>>, QueryKey, GetMatchesParams['cursor']> = ({
    signal,
    pageParam
  }) => getMatches(projectId, { ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getMatches>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getMatches>>,
    QueryKey,
    GetMatchesParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetMatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getMatches>>>;
export type GetMatchesInfiniteQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetMatchesInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getMatches>>, GetMatchesParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetMatchesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getMatches>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getMatches>>,
        QueryKey,
        GetMatchesParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getMatchesMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMatchesInfiniteQueryOptions(projectId, params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetMatchesQueryOptions = <TData = Awaited<ReturnType<typeof getMatches>>, TError = unknown>(
  projectId: string,
  params?: GetMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMatchesQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMatches>>> = ({ signal }) =>
    getMatches(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getMatches>>>;
export type GetMatchesQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetMatches = <TData = Awaited<ReturnType<typeof getMatches>>, TError = unknown>(
  projectId: string,
  params?: GetMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getMatchesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMatchesQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the matches
 */
export const getPlayerMatches = (
  projectId: string,
  params?: GetPlayerMatchesParams,
  options?: SecondParameter<typeof getPlayerMatchesMutator>,
  signal?: AbortSignal
) => {
  return getPlayerMatchesMutator<MatchesSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/player-matches`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlayerMatchesQueryKey = (projectId: string, params?: GetPlayerMatchesParams) => {
  return [`/project/${projectId}/search/player-matches`, ...(params ? [params] : [])] as const;
};

export const getGetPlayerMatchesQueryOptions = <TData = Awaited<ReturnType<typeof getPlayerMatches>>, TError = unknown>(
  projectId: string,
  params?: GetPlayerMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerMatchesQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayerMatches>>> = ({ signal }) =>
    getPlayerMatches(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerMatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayerMatches>>>;
export type GetPlayerMatchesQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetPlayerMatches = <TData = Awaited<ReturnType<typeof getPlayerMatches>>, TError = unknown>(
  projectId: string,
  params?: GetPlayerMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerMatchesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayerMatchesQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player metrics
 */
export const getPlayersMetrics = (
  projectId: string,
  params?: GetPlayersMetricsParams,
  options?: SecondParameter<typeof getPlayersMetricsMutator>,
  signal?: AbortSignal
) => {
  return getPlayersMetricsMutator<ReportMetricsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/player-metrics`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlayersMetricsQueryKey = (projectId: string, params?: GetPlayersMetricsParams) => {
  return [`/project/${projectId}/search/player-metrics`, ...(params ? [params] : [])] as const;
};

export const getGetPlayersMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayersMetrics>>,
  TError = unknown
>(
  projectId: string,
  params?: GetPlayersMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersMetricsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersMetricsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayersMetrics>>> = ({ signal }) =>
    getPlayersMetrics(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayersMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayersMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayersMetrics>>>;
export type GetPlayersMetricsQueryError = unknown;

/**
 * @summary Gets all of the player metrics
 */
export const useGetPlayersMetrics = <TData = Awaited<ReturnType<typeof getPlayersMetrics>>, TError = unknown>(
  projectId: string,
  params?: GetPlayersMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersMetricsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayersMetricsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player positions
 */
export const getPlayerPositions = (
  projectId: string,
  options?: SecondParameter<typeof getPlayerPositionsMutator>,
  signal?: AbortSignal
) => {
  return getPlayerPositionsMutator<string[]>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/player-positions`, method: 'GET', signal },
    options
  );
};

export const getGetPlayerPositionsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/search/player-positions`] as const;
};

export const getGetPlayerPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerPositions>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerPositionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerPositionsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayerPositions>>> = ({ signal }) =>
    getPlayerPositions(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerPositionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayerPositions>>>;
export type GetPlayerPositionsQueryError = unknown;

/**
 * @summary Gets all of the player positions
 */
export const useGetPlayerPositions = <TData = Awaited<ReturnType<typeof getPlayerPositions>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerPositionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayerPositionsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player season metrics
 */
export const getPlayersSeasonMetrics = (
  projectId: string,
  params?: GetPlayersSeasonMetricsParams,
  options?: SecondParameter<typeof getPlayersSeasonMetricsMutator>,
  signal?: AbortSignal
) => {
  return getPlayersSeasonMetricsMutator<SeasonMetricsSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/search/player-season-metrics`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetPlayersSeasonMetricsQueryKey = (projectId: string, params?: GetPlayersSeasonMetricsParams) => {
  return [`/project/${projectId}/search/player-season-metrics`, ...(params ? [params] : [])] as const;
};

export const getGetPlayersSeasonMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
  TError = unknown
>(
  projectId: string,
  params?: GetPlayersSeasonMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersSeasonMetricsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersSeasonMetricsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>> = ({ signal }) =>
    getPlayersSeasonMetrics(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayersSeasonMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>>;
export type GetPlayersSeasonMetricsQueryError = unknown;

/**
 * @summary Gets all of the player season metrics
 */
export const useGetPlayersSeasonMetrics = <
  TData = Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
  TError = unknown
>(
  projectId: string,
  params?: GetPlayersSeasonMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersSeasonMetricsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayersSeasonMetricsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player sub positions
 */
export const getPlayerSubPositions = (
  projectId: string,
  options?: SecondParameter<typeof getPlayerSubPositionsMutator>,
  signal?: AbortSignal
) => {
  return getPlayerSubPositionsMutator<SubPositionsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/player-subpositions`, method: 'GET', signal },
    options
  );
};

export const getGetPlayerSubPositionsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/search/player-subpositions`] as const;
};

export const getGetPlayerSubPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerSubPositions>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerSubPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerSubPositionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerSubPositionsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayerSubPositions>>> = ({ signal }) =>
    getPlayerSubPositions(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerSubPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerSubPositionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayerSubPositions>>>;
export type GetPlayerSubPositionsQueryError = unknown;

/**
 * @summary Gets all of the player sub positions
 */
export const useGetPlayerSubPositions = <TData = Awaited<ReturnType<typeof getPlayerSubPositions>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerSubPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerSubPositionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayerSubPositionsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the players
 */
export const getPlayers = (
  projectId: string,
  params?: GetPlayersParams,
  options?: SecondParameter<typeof getPlayersMutator>,
  signal?: AbortSignal
) => {
  return getPlayersMutator<PlayersQuerySchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/players`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlayersQueryKey = (projectId: string, params?: GetPlayersParams) => {
  return [`/project/${projectId}/search/players`, ...(params ? [params] : [])] as const;
};

export const getGetPlayersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getPlayers>>, GetPlayersParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetPlayersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getPlayers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getPlayers>>,
        QueryKey,
        GetPlayersParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayers>>, QueryKey, GetPlayersParams['cursor']> = ({
    signal,
    pageParam
  }) => getPlayers(projectId, { ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPlayers>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getPlayers>>,
    QueryKey,
    GetPlayersParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetPlayersInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayers>>>;
export type GetPlayersInfiniteQueryError = unknown;

/**
 * @summary Gets all of the players
 */
export const useGetPlayersInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getPlayers>>, GetPlayersParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetPlayersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getPlayers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getPlayers>>,
        QueryKey,
        GetPlayersParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getPlayersMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayersInfiniteQueryOptions(projectId, params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetPlayersQueryOptions = <TData = Awaited<ReturnType<typeof getPlayers>>, TError = unknown>(
  projectId: string,
  params?: GetPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayers>>> = ({ signal }) =>
    getPlayers(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayers>>>;
export type GetPlayersQueryError = unknown;

/**
 * @summary Gets all of the players
 */
export const useGetPlayers = <TData = Awaited<ReturnType<typeof getPlayers>>, TError = unknown>(
  projectId: string,
  params?: GetPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayersQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Search players based on the given criteria
 */
export const searchPlayers = (
  projectId: string,
  params: SearchPlayersParams,
  options?: SecondParameter<typeof searchPlayersMutator>,
  signal?: AbortSignal
) => {
  return searchPlayersMutator<PlayersQuerySchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/search-players`, method: 'GET', params, signal },
    options
  );
};

export const getSearchPlayersQueryKey = (projectId: string, params: SearchPlayersParams) => {
  return [`/project/${projectId}/search/search-players`, ...(params ? [params] : [])] as const;
};

export const getSearchPlayersQueryOptions = <TData = Awaited<ReturnType<typeof searchPlayers>>, TError = unknown>(
  projectId: string,
  params: SearchPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof searchPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchPlayersQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPlayers>>> = ({ signal }) =>
    searchPlayers(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof searchPlayers>>>;
export type SearchPlayersQueryError = unknown;

/**
 * @summary Search players based on the given criteria
 */
export const useSearchPlayers = <TData = Awaited<ReturnType<typeof searchPlayers>>, TError = unknown>(
  projectId: string,
  params: SearchPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof searchPlayersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchPlayersQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the teams
 */
export const getTeams = (
  projectId: string,
  params?: GetTeamsParams,
  options?: SecondParameter<typeof getTeamsMutator>,
  signal?: AbortSignal
) => {
  return getTeamsMutator<TeamsQuerySchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/search/teams`, method: 'GET', params, signal },
    options
  );
};

export const getGetTeamsQueryKey = (projectId: string, params?: GetTeamsParams) => {
  return [`/project/${projectId}/search/teams`, ...(params ? [params] : [])] as const;
};

export const getGetTeamsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getTeams>>, GetTeamsParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetTeamsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getTeams>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getTeams>>,
        QueryKey,
        GetTeamsParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>, QueryKey, GetTeamsParams['cursor']> = ({
    signal,
    pageParam
  }) => getTeams(projectId, { ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getTeams>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getTeams>>,
    QueryKey,
    GetTeamsParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetTeamsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>;
export type GetTeamsInfiniteQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetTeamsInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getTeams>>, GetTeamsParams['cursor']>,
  TError = unknown
>(
  projectId: string,
  params?: GetTeamsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getTeams>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getTeams>>,
        QueryKey,
        GetTeamsParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getTeamsMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamsInfiniteQueryOptions(projectId, params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getTeams>>, TError = unknown>(
  projectId: string,
  params?: GetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({ signal }) =>
    getTeams(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>;
export type GetTeamsQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetTeams = <TData = Awaited<ReturnType<typeof getTeams>>, TError = unknown>(
  projectId: string,
  params?: GetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getTeamsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
