import { CellContext } from '@tanstack/react-table';
import Report from 'assets/report.svg?react';
import { useGetReports, useGetReportsCount } from 'lib/report/report';
import { AdminProjectSchema } from 'modules/admin/interfaces';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';

function ReportsCell({ row }: CellContext<AdminProjectSchema, unknown>) {
  const projectId = row.original.id!;
  const { data: reportCount, isFetching } = useGetReportsCount(
    projectId,
    { query: { queryKey: ['project', projectId, 'reports', 'count'], staleTime: Infinity, refetchOnWindowFocus: false } }
  );
  if (isFetching) return <LoadingCell />;

  return (
    <div className="mx-2 flex justify-center gap-1 text-xs">
      <Report className="size-5 fill-gray-700" /> {reportCount}
    </div>
  );
}

export default ReportsCell;
