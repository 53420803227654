import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ReportCreateSchemaReportType } from 'lib/model';
import { useCreateReport } from 'lib/report/report';
import { useGetCompetitions } from 'lib/search/search';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DialogProps, ValueOption } from 'utils/interfaces';
import { competitionToAutocompleteOption } from 'utils/mappings';

interface NewLeagueReportFormValues {
  name: string;
  league: ValueOption | null;
}

const NewLeagueReportDialog = ({ open, setOpen }: DialogProps) => {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<NewLeagueReportFormValues>({
    defaultValues: {
      name: '',
      league: null
    }
  });

  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const { data: leagueData, isFetching: isFetchingCompetitons } = useGetCompetitions(
    project.id!,
    {
      competition_name: query
    },
    {
      query: {
        queryKey: [project.id, 'competitions', query],
        staleTime: 1000 * 60 * 5
      }
    }
  );
  // const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetTeamsInfinite(
  //   {
  //     team_name: query,
  //     project_id: project.id
  //   },
  //   {
  //     query: {
  //       queryKey: ['teams', project.id, query],
  //       staleTime: 1000 * 60 * 5,
  //       initialPageParam: 0,
  //       getNextPageParam: (lastPage, pages, lastPageParam) => {
  //         if (!lastPage.teams || lastPage.teams?.length < 100) {
  //           return undefined;
  //         }

  //         return lastPageParam ? lastPageParam + 1 : 1;
  //       }
  //     }
  //   }
  // );

  const leagueOptions = useMemo(() => {
    // const allRows = league ? data.pages.flatMap((d) => d.teams) : [];
    const allRows = leagueData ? leagueData : [];
    return allRows.map(competitionToAutocompleteOption) ?? [];
    return [];
  }, [leagueData]);

  const { mutate: createReport, isPending: isCreating } = useCreateReport();

  function handleSubmit(data: NewLeagueReportFormValues) {
    if (!data.league) return;
    createReport(
      {
        projectId: project!.id!,
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.league,
          project: project!.id!,
          report_entities: [data.league.id as number]
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['project', project.id!, 'reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedLeague = watch('league');
  useEffect(() => {
    if (selectedLeague) {
      let reportName = `${selectedLeague.label}`;
      if (selectedLeague.secondaryLabel) {
        reportName = reportName.concat(` | ${selectedLeague.secondaryLabel}`);
      }
      setValue('name', reportName);
    } else {
      setValue('name', '');
    }
  }, [selectedLeague, setValue]);

  return (
    <DialogBase title="New League Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="new-league-report-form" onSubmit={formSubmit(handleSubmit)}>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Competition</span>
            <SelectInput
              label={'Competition'}
              loading={isFetchingCompetitons}
              placeholder="Start typing: Competition name"
              options={leagueOptions}
              error={errors.league}
              onInputChange={setQuery}
              searchable
              formProps={{
                name: 'league',
                rules: { required: { value: true, message: 'Competition is required.' } },
                control: control
              }}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-league-report-form" loading={isCreating}>
          <span>Create Competition Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
};

export default NewLeagueReportDialog;
