/* eslint-disable react/jsx-no-bind */
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { getReportElementColor } from 'modules/reports/colors';
import { memo, useMemo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { LineChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import {
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../reportCards/interfaces';
import { AttributeValues } from '../interfaces';
import { CompetitionChartPoint, LineChartPoint, TeamChartPoint } from './interfaces';

interface LineChartInfoProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const LineChartInfo = memo(function LineChartInfo({ report, element }: LineChartInfoProps) {
  const attributeValues = element.attribute_values as AttributeValues<LineChartElementFormValues>;
  const events: LineChartPoint[] = element.entity_data?.events ?? [];
  const type = attributeValues?.type?.[0] ?? 'For specific match';

  const EntityLegend = useMemo(() => {
    switch (report.report_type) {
      case 'match': {
        const generalData = report.general_data as MatchGeneralData;
        return (
          <div className="flex flex-wrap items-center gap-6">
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { teamId: generalData.home_team_id })}
              name={generalData.home_team_name}
            />
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { teamId: generalData.away_team_id })}
              name={generalData.away_team_name}
            />
          </div>
        );
      }
      case 'team': {
        const generalData = report.general_data as TeamGeneralData;
        return (
          <div className="flex flex-col gap-3">
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { teamId: generalData.team_id })}
              name={generalData.team_name}
            />
          </div>
        );
      }
      case 'player': {
        const generalData = report.general_data as PlayerGeneralData;
        return (
          <div className="flex flex-col gap-3">
            <LineChartInfoCircle
              color={getReportElementColor(report, element, {
                teamId: generalData.team_id,
                playerId: generalData.player_id
              })}
              name={generalData.player_name}
            />
          </div>
        );
      }
      case 'team_comparison': {
        const { team1, team2 } = report.general_data as TeamComparisonGeneralData;
        return (
          <div className="flex flex-wrap items-center gap-6">
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { teamId: team1.team_id })}
              name={team1.team_name}
            />
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { teamId: team2.team_id })}
              name={team2.team_name}
            />
          </div>
        );
      }
      case 'player_comparison': {
        const { player1, player2 } = report.general_data as PlayerComparisonGeneralData;
        return (
          <div className="flex flex-wrap items-center gap-6">
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { playerId: player1.player_id, teamId: player1.team_id })}
              name={player1.player_name}
            />
            <LineChartInfoCircle
              color={getReportElementColor(report, element, { playerId: player2.player_id, teamId: player2.team_id })}
              name={player2.player_name}
            />
          </div>
        );
      }
      case 'league': {
        // TODO: Currently, there is no team data in report/element
        const elementData = (element.entity_data?.events as TeamChartPoint[]) ?? [];
        const teamEvents = elementData?.reduce(
          (acc, event) => {
            if (!acc[event.team_id]) {
              acc[event.team_id] = event;
            }
            return acc;
          },
          {} as { [key: string]: TeamChartPoint }
        );
        const teams = Object.values(teamEvents).map((team) => {
          const teamColor = team.team_color;
          if (!teamColor || teamColor === '') {
            return { ...team, team_color: getReportElementColor(report, element, { teamId: team.team_id }) };
          }
          return team;
        });

        return (
          <div className="flex flex-wrap items-center gap-6">
            {teams.map((team, i) => (
              <LineChartInfoCircle key={team.team_id} color={team.team_color} name={team.team_name} />
            ))}
          </div>
        );
      }
      case 'scout': {
        const generalData = report.general_data as ScoutGeneralData;
        const allPlayers = [...generalData.players_manual, ...generalData.players_filtered];
        return (
          <div className="flex flex-wrap items-center gap-6">
            {allPlayers.map((player, i) => (
              <LineChartInfoCircle
                key={player.player_id}
                color={getReportElementColor(report, element, { playerId: player.player_id })}
                name={player.player_name}
              />
            ))}
          </div>
        );
      }
      default:
        return null;
    }
  }, [report.report_type, report.general_data, type]);

  const CompetitonLegend = useMemo(() => {
    if (!element.entity_data?.competition_events) {
      return null;
    }
    const competitionEvents = element.entity_data.competition_events as CompetitionChartPoint[];
    const competitions = competitionEvents.reduce(
      (acc, event) => {
        if (!acc[event.competition_id]) {
          acc[event.competition_id] = event;
        }
        return acc;
      },
      {} as { [key: string]: CompetitionChartPoint }
    );
    const allCompetitions = Object.values(competitions);

    const timeScale = attributeValues?.time_scale?.[0] ?? 'By season';
    let period = '';
    switch (timeScale) {
      case 'By season':
        period = 'Season';
        break;
      case 'By match':
      case 'By match (last 10)':
        period = 'Match';
        break;
      case 'Match time':
        period = 'Match time';
        break;
    }
    let reportType = '';
    switch (report.report_type) {
      case 'team':
      case 'team_comparison':
        reportType = 'team';
        break;
      case 'player':
      case 'player_comparison':
      case 'scout':
        reportType = 'player';
        break;
    }

    return (
      <div className="flex flex-wrap items-center gap-6">
        {allCompetitions.map((competition, i) => (
          <LineChartInfoCircle
            key={competition.competition_id}
            color={getReportElementColor(report, element, {
              competitionId: competition.competition_id,
              isCompetition: true
            })}
            name={`${competition.competition_name} - ${period} ${reportType} average`}
          />
        ))}
      </div>
    );
  }, [element.entity_data?.competition_events, report.report_type, element.attribute_values?.time_scale]);

  return (
    <div className="flex flex-col gap-2">
      {EntityLegend}
      {CompetitonLegend}
    </div>
  );
});

const LineChartInfoCircle = memo(function LineChartInfoCircle({ name, color }: { name: string; color: string }) {
  return (
    <div className="flex items-center gap-2">
      <span
        className={twJoin('size-3 rounded-full border', isLightColor(color) ? 'border-gray-400' : 'border-transparent')}
        style={{ backgroundColor: color }}
      />
      <span className="text-xs font-semibold">{name}</span>
    </div>
  );
});

export default LineChartInfo;
