import { memo } from 'react';
import { BaseReportElementProps } from '../interfaces';
import MatchHeatMapElement from './MatchHeatMapElement';
import SubjectHeatMapElement from './SubjectHeatMapElement';

const HeatMapElement = memo(function HeatMapElement({ element, report }: BaseReportElementProps) {
  return (
    <div className="flex w-full justify-center">
      {report.report_type === 'match' ? (
        <MatchHeatMapElement element={element} report={report} />
      ) : (
        <SubjectHeatMapElement element={element} report={report} />
      )}
    </div>
  );
});

export default HeatMapElement;
