/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as changeReportElementPositionMutator,
  default as checkPlayersColumnExistsMutator,
  default as createReportElementMutator,
  default as createReportMutator,
  default as createReportScheduleMutator,
  default as deleteReportElementMutator,
  default as deleteReportMutator,
  default as deleteReportScheduleMutator,
  default as editReportElementMutator,
  default as editReportMutator,
  default as getFavoriteReportsMutator,
  default as getLastEditedReportsMutator,
  default as getPublicReportElementMutator,
  default as getPublicReportMutator,
  default as getReportElementMutator,
  default as getReportElementTemplatesMutator,
  default as getReportMutator,
  default as getReportScheduleMutator,
  default as getReportsCountMutator,
  default as getReportsMutator,
  default as getReportUsersMutator,
  default as grantReportAccessMutator,
  default as removeReportAccessMutator,
  default as sendReportMutator,
  default as toggleFavoriteReportMutator,
  default as updateReportAccessMutator,
  default as updateReportScheduleMutator
} from '../../api/axios';
import type {
  ChangeReportElementPositionParams,
  CheckPlayersColumnExistsParams,
  CreateReportBody,
  DeleteReportElementParams,
  GetLastEditedReportsParams,
  GetPublicReportElementParams,
  GetPublicReportParams,
  GetReportsParams,
  ReportAccessCreateSchema,
  ReportAccessDeleteSchema,
  ReportAccessesCreateSchema,
  ReportAccessesSchema,
  ReportEditSchema,
  ReportElementCreateSchema,
  ReportElementEditSchema,
  ReportElementSchema,
  ReportElementTemplatesSchema,
  ReportNestedSchema,
  ReportSendScheduleCreateSchema,
  ReportSendScheduleSchema,
  ReportsSchema,
  SendReportParams
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Gets all of the reports a user has access to
 */
export const getReports = (
  projectId: string,
  params?: GetReportsParams,
  options?: SecondParameter<typeof getReportsMutator>,
  signal?: AbortSignal
) => {
  return getReportsMutator<ReportsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/report`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportsQueryKey = (projectId: string, params?: GetReportsParams) => {
  return [`/project/${projectId}/report`, ...(params ? [params] : [])] as const;
};

export const getGetReportsQueryOptions = <TData = Awaited<ReturnType<typeof getReports>>, TError = unknown>(
  projectId: string,
  params?: GetReportsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>>;
    request?: SecondParameter<typeof getReportsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReports>>> = ({ signal }) =>
    getReports(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getReports>>>;
export type GetReportsQueryError = unknown;

/**
 * @summary Gets all of the reports a user has access to
 */
export const useGetReports = <TData = Awaited<ReturnType<typeof getReports>>, TError = unknown>(
  projectId: string,
  params?: GetReportsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>>;
    request?: SecondParameter<typeof getReportsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new report
 */
export const createReport = (
  projectId: string,
  createReportBody: CreateReportBody,
  options?: SecondParameter<typeof createReportMutator>
) => {
  return createReportMutator<ReportNestedSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createReportBody
    },
    options
  );
};

export const getCreateReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReport>>,
    TError,
    { projectId: string; data: CreateReportBody },
    TContext
  >;
  request?: SecondParameter<typeof createReportMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReport>>,
  TError,
  { projectId: string; data: CreateReportBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReport>>,
    { projectId: string; data: CreateReportBody }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return createReport(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReportMutationResult = NonNullable<Awaited<ReturnType<typeof createReport>>>;
export type CreateReportMutationBody = CreateReportBody;
export type CreateReportMutationError = unknown;

/**
 * @summary Creates a new report
 */
export const useCreateReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReport>>,
    TError,
    { projectId: string; data: CreateReportBody },
    TContext
  >;
  request?: SecondParameter<typeof createReportMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReport>>,
  TError,
  { projectId: string; data: CreateReportBody },
  TContext
> => {
  const mutationOptions = getCreateReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Checks if a column exists in players table used for scout reports
 */
export const checkPlayersColumnExists = (
  projectId: string,
  params: CheckPlayersColumnExistsParams,
  options?: SecondParameter<typeof checkPlayersColumnExistsMutator>,
  signal?: AbortSignal
) => {
  return checkPlayersColumnExistsMutator<boolean>(
    { url: `/project/${encodeURIComponent(String(projectId))}/report/column-exists`, method: 'GET', params, signal },
    options
  );
};

export const getCheckPlayersColumnExistsQueryKey = (projectId: string, params: CheckPlayersColumnExistsParams) => {
  return [`/project/${projectId}/report/column-exists`, ...(params ? [params] : [])] as const;
};

export const getCheckPlayersColumnExistsQueryOptions = <
  TData = Awaited<ReturnType<typeof checkPlayersColumnExists>>,
  TError = unknown
>(
  projectId: string,
  params: CheckPlayersColumnExistsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkPlayersColumnExists>>, TError, TData>>;
    request?: SecondParameter<typeof checkPlayersColumnExistsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckPlayersColumnExistsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkPlayersColumnExists>>> = ({ signal }) =>
    checkPlayersColumnExists(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkPlayersColumnExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckPlayersColumnExistsQueryResult = NonNullable<Awaited<ReturnType<typeof checkPlayersColumnExists>>>;
export type CheckPlayersColumnExistsQueryError = unknown;

/**
 * @summary Checks if a column exists in players table used for scout reports
 */
export const useCheckPlayersColumnExists = <
  TData = Awaited<ReturnType<typeof checkPlayersColumnExists>>,
  TError = unknown
>(
  projectId: string,
  params: CheckPlayersColumnExistsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkPlayersColumnExists>>, TError, TData>>;
    request?: SecondParameter<typeof checkPlayersColumnExistsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckPlayersColumnExistsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets number of reports in project
 */
export const getReportsCount = (
  projectId: string,
  options?: SecondParameter<typeof getReportsCountMutator>,
  signal?: AbortSignal
) => {
  return getReportsCountMutator<number>(
    { url: `/project/${encodeURIComponent(String(projectId))}/report/count`, method: 'GET', signal },
    options
  );
};

export const getGetReportsCountQueryKey = (projectId: string) => {
  return [`/project/${projectId}/report/count`] as const;
};

export const getGetReportsCountQueryOptions = <TData = Awaited<ReturnType<typeof getReportsCount>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportsCount>>, TError, TData>>;
    request?: SecondParameter<typeof getReportsCountMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportsCountQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportsCount>>> = ({ signal }) =>
    getReportsCount(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportsCountQueryResult = NonNullable<Awaited<ReturnType<typeof getReportsCount>>>;
export type GetReportsCountQueryError = unknown;

/**
 * @summary Gets number of reports in project
 */
export const useGetReportsCount = <TData = Awaited<ReturnType<typeof getReportsCount>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportsCount>>, TError, TData>>;
    request?: SecondParameter<typeof getReportsCountMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportsCountQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the favorite reports
 */
export const getFavoriteReports = (
  projectId: string,
  options?: SecondParameter<typeof getFavoriteReportsMutator>,
  signal?: AbortSignal
) => {
  return getFavoriteReportsMutator<ReportsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/report/favorite`, method: 'GET', signal },
    options
  );
};

export const getGetFavoriteReportsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/report/favorite`] as const;
};

export const getGetFavoriteReportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFavoriteReports>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFavoriteReports>>, TError, TData>>;
    request?: SecondParameter<typeof getFavoriteReportsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFavoriteReportsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFavoriteReports>>> = ({ signal }) =>
    getFavoriteReports(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFavoriteReports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFavoriteReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getFavoriteReports>>>;
export type GetFavoriteReportsQueryError = unknown;

/**
 * @summary Gets all of the favorite reports
 */
export const useGetFavoriteReports = <TData = Awaited<ReturnType<typeof getFavoriteReports>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFavoriteReports>>, TError, TData>>;
    request?: SecondParameter<typeof getFavoriteReportsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFavoriteReportsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets last edited reports based on the number specified, or last 3 reports if not specified
 */
export const getLastEditedReports = (
  projectId: string,
  params?: GetLastEditedReportsParams,
  options?: SecondParameter<typeof getLastEditedReportsMutator>,
  signal?: AbortSignal
) => {
  return getLastEditedReportsMutator<ReportsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/report/last-edited`, method: 'GET', params, signal },
    options
  );
};

export const getGetLastEditedReportsQueryKey = (projectId: string, params?: GetLastEditedReportsParams) => {
  return [`/project/${projectId}/report/last-edited`, ...(params ? [params] : [])] as const;
};

export const getGetLastEditedReportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastEditedReports>>,
  TError = unknown
>(
  projectId: string,
  params?: GetLastEditedReportsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLastEditedReports>>, TError, TData>>;
    request?: SecondParameter<typeof getLastEditedReportsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLastEditedReportsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLastEditedReports>>> = ({ signal }) =>
    getLastEditedReports(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastEditedReports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastEditedReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getLastEditedReports>>>;
export type GetLastEditedReportsQueryError = unknown;

/**
 * @summary Gets last edited reports based on the number specified, or last 3 reports if not specified
 */
export const useGetLastEditedReports = <TData = Awaited<ReturnType<typeof getLastEditedReports>>, TError = unknown>(
  projectId: string,
  params?: GetLastEditedReportsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLastEditedReports>>, TError, TData>>;
    request?: SecondParameter<typeof getLastEditedReportsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLastEditedReportsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deletes an existing report
 */
export const deleteReport = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof deleteReportMutator>
) => {
  return deleteReportMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReport>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReport>>,
    { projectId: string; reportId: string }
  > = (props) => {
    const { projectId, reportId } = props ?? {};

    return deleteReport(projectId, reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReport>>>;

export type DeleteReportMutationError = unknown;

/**
 * @summary Deletes an existing report
 */
export const useDeleteReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReport>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const mutationOptions = getDeleteReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report with all of its elements
 */
export const getReport = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof getReportMutator>,
  signal?: AbortSignal
) => {
  return getReportMutator<ReportNestedSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportQueryKey = (projectId: string, reportId: string) => {
  return [`/project/${projectId}/report/${reportId}`] as const;
};

export const getGetReportQueryOptions = <TData = Awaited<ReturnType<typeof getReport>>, TError = unknown>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>>;
    request?: SecondParameter<typeof getReportMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportQueryKey(projectId, reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReport>>> = ({ signal }) =>
    getReport(projectId, reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && reportId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportQueryResult = NonNullable<Awaited<ReturnType<typeof getReport>>>;
export type GetReportQueryError = unknown;

/**
 * @summary Gets a report with all of its elements
 */
export const useGetReport = <TData = Awaited<ReturnType<typeof getReport>>, TError = unknown>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>>;
    request?: SecondParameter<typeof getReportMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportQueryOptions(projectId, reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Modifies an existing report
 */
export const editReport = (
  projectId: string,
  reportId: string,
  reportEditSchema: ReportEditSchema,
  options?: SecondParameter<typeof editReportMutator>
) => {
  return editReportMutator<ReportNestedSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportEditSchema
    },
    options
  );
};

export const getEditReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editReport>>,
    TError,
    { projectId: string; reportId: string; data: ReportEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof editReportMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editReport>>,
  TError,
  { projectId: string; reportId: string; data: ReportEditSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editReport>>,
    { projectId: string; reportId: string; data: ReportEditSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return editReport(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditReportMutationResult = NonNullable<Awaited<ReturnType<typeof editReport>>>;
export type EditReportMutationBody = ReportEditSchema;
export type EditReportMutationError = unknown;

/**
 * @summary Modifies an existing report
 */
export const useEditReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editReport>>,
    TError,
    { projectId: string; reportId: string; data: ReportEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof editReportMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editReport>>,
  TError,
  { projectId: string; reportId: string; data: ReportEditSchema },
  TContext
> => {
  const mutationOptions = getEditReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Adds a report element to an existing report
 */
export const createReportElement = (
  projectId: string,
  reportId: string,
  reportElementCreateSchema: ReportElementCreateSchema,
  options?: SecondParameter<typeof createReportElementMutator>
) => {
  return createReportElementMutator<ReportElementSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportElementCreateSchema
    },
    options
  );
};

export const getCreateReportElementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReportElement>>,
    TError,
    { projectId: string; reportId: string; data: ReportElementCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createReportElementMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReportElement>>,
  TError,
  { projectId: string; reportId: string; data: ReportElementCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReportElement>>,
    { projectId: string; reportId: string; data: ReportElementCreateSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return createReportElement(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReportElementMutationResult = NonNullable<Awaited<ReturnType<typeof createReportElement>>>;
export type CreateReportElementMutationBody = ReportElementCreateSchema;
export type CreateReportElementMutationError = unknown;

/**
 * @summary Adds a report element to an existing report
 */
export const useCreateReportElement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReportElement>>,
    TError,
    { projectId: string; reportId: string; data: ReportElementCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createReportElementMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReportElement>>,
  TError,
  { projectId: string; reportId: string; data: ReportElementCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateReportElementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets all of the report element templates
 */
export const getReportElementTemplates = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof getReportElementTemplatesMutator>,
  signal?: AbortSignal
) => {
  return getReportElementTemplatesMutator<ReportElementTemplatesSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element-templates`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportElementTemplatesQueryKey = (projectId: string, reportId: string) => {
  return [`/project/${projectId}/report/${reportId}/element-templates`] as const;
};

export const getGetReportElementTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportElementTemplates>>,
  TError = unknown
>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElementTemplates>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementTemplatesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportElementTemplatesQueryKey(projectId, reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportElementTemplates>>> = ({ signal }) =>
    getReportElementTemplates(projectId, reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && reportId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportElementTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportElementTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getReportElementTemplates>>>;
export type GetReportElementTemplatesQueryError = unknown;

/**
 * @summary Gets all of the report element templates
 */
export const useGetReportElementTemplates = <
  TData = Awaited<ReturnType<typeof getReportElementTemplates>>,
  TError = unknown
>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElementTemplates>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementTemplatesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportElementTemplatesQueryOptions(projectId, reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deletes a report element
 */
export const deleteReportElement = (
  projectId: string,
  reportId: string,
  elementId: string,
  params?: DeleteReportElementParams,
  options?: SecondParameter<typeof deleteReportElementMutator>
) => {
  return deleteReportElementMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'DELETE',
      params
    },
    options
  );
};

export const getDeleteReportElementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportElement>>,
    TError,
    { projectId: string; reportId: string; elementId: string; params?: DeleteReportElementParams },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportElementMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReportElement>>,
  TError,
  { projectId: string; reportId: string; elementId: string; params?: DeleteReportElementParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReportElement>>,
    { projectId: string; reportId: string; elementId: string; params?: DeleteReportElementParams }
  > = (props) => {
    const { projectId, reportId, elementId, params } = props ?? {};

    return deleteReportElement(projectId, reportId, elementId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportElementMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReportElement>>>;

export type DeleteReportElementMutationError = unknown;

/**
 * @summary Deletes a report element
 */
export const useDeleteReportElement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportElement>>,
    TError,
    { projectId: string; reportId: string; elementId: string; params?: DeleteReportElementParams },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportElementMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReportElement>>,
  TError,
  { projectId: string; reportId: string; elementId: string; params?: DeleteReportElementParams },
  TContext
> => {
  const mutationOptions = getDeleteReportElementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report element
 */
export const getReportElement = (
  projectId: string,
  reportId: string,
  elementId: string,
  options?: SecondParameter<typeof getReportElementMutator>,
  signal?: AbortSignal
) => {
  return getReportElementMutator<ReportElementSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportElementQueryKey = (projectId: string, reportId: string, elementId: string) => {
  return [`/project/${projectId}/report/${reportId}/element/${elementId}`] as const;
};

export const getGetReportElementQueryOptions = <TData = Awaited<ReturnType<typeof getReportElement>>, TError = unknown>(
  projectId: string,
  reportId: string,
  elementId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElement>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportElementQueryKey(projectId, reportId, elementId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportElement>>> = ({ signal }) =>
    getReportElement(projectId, reportId, elementId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && reportId && elementId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportElement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportElementQueryResult = NonNullable<Awaited<ReturnType<typeof getReportElement>>>;
export type GetReportElementQueryError = unknown;

/**
 * @summary Gets a report element
 */
export const useGetReportElement = <TData = Awaited<ReturnType<typeof getReportElement>>, TError = unknown>(
  projectId: string,
  reportId: string,
  elementId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElement>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportElementQueryOptions(projectId, reportId, elementId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Modifies an existing report element
 */
export const editReportElement = (
  projectId: string,
  reportId: string,
  elementId: string,
  reportElementEditSchema: ReportElementEditSchema,
  options?: SecondParameter<typeof editReportElementMutator>
) => {
  return editReportElementMutator<ReportElementSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportElementEditSchema
    },
    options
  );
};

export const getEditReportElementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editReportElement>>,
    TError,
    { projectId: string; reportId: string; elementId: string; data: ReportElementEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof editReportElementMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editReportElement>>,
  TError,
  { projectId: string; reportId: string; elementId: string; data: ReportElementEditSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editReportElement>>,
    { projectId: string; reportId: string; elementId: string; data: ReportElementEditSchema }
  > = (props) => {
    const { projectId, reportId, elementId, data } = props ?? {};

    return editReportElement(projectId, reportId, elementId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditReportElementMutationResult = NonNullable<Awaited<ReturnType<typeof editReportElement>>>;
export type EditReportElementMutationBody = ReportElementEditSchema;
export type EditReportElementMutationError = unknown;

/**
 * @summary Modifies an existing report element
 */
export const useEditReportElement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editReportElement>>,
    TError,
    { projectId: string; reportId: string; elementId: string; data: ReportElementEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof editReportElementMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editReportElement>>,
  TError,
  { projectId: string; reportId: string; elementId: string; data: ReportElementEditSchema },
  TContext
> => {
  const mutationOptions = getEditReportElementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Changes the position of a report element
 */
export const changeReportElementPosition = (
  projectId: string,
  reportId: string,
  elementId: string,
  params: ChangeReportElementPositionParams,
  options?: SecondParameter<typeof changeReportElementPositionMutator>
) => {
  return changeReportElementPositionMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}/position`,
      method: 'PUT',
      params
    },
    options
  );
};

export const getChangeReportElementPositionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeReportElementPosition>>,
    TError,
    { projectId: string; reportId: string; elementId: string; params: ChangeReportElementPositionParams },
    TContext
  >;
  request?: SecondParameter<typeof changeReportElementPositionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeReportElementPosition>>,
  TError,
  { projectId: string; reportId: string; elementId: string; params: ChangeReportElementPositionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeReportElementPosition>>,
    { projectId: string; reportId: string; elementId: string; params: ChangeReportElementPositionParams }
  > = (props) => {
    const { projectId, reportId, elementId, params } = props ?? {};

    return changeReportElementPosition(projectId, reportId, elementId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeReportElementPositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeReportElementPosition>>
>;

export type ChangeReportElementPositionMutationError = unknown;

/**
 * @summary Changes the position of a report element
 */
export const useChangeReportElementPosition = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeReportElementPosition>>,
    TError,
    { projectId: string; reportId: string; elementId: string; params: ChangeReportElementPositionParams },
    TContext
  >;
  request?: SecondParameter<typeof changeReportElementPositionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeReportElementPosition>>,
  TError,
  { projectId: string; reportId: string; elementId: string; params: ChangeReportElementPositionParams },
  TContext
> => {
  const mutationOptions = getChangeReportElementPositionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes a report schedule
 */
export const deleteReportSchedule = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof deleteReportScheduleMutator>
) => {
  return deleteReportScheduleMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteReportScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportSchedule>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReportSchedule>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReportSchedule>>,
    { projectId: string; reportId: string }
  > = (props) => {
    const { projectId, reportId } = props ?? {};

    return deleteReportSchedule(projectId, reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReportSchedule>>>;

export type DeleteReportScheduleMutationError = unknown;

/**
 * @summary Deletes a report schedule
 */
export const useDeleteReportSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportSchedule>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReportSchedule>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const mutationOptions = getDeleteReportScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the schedule of a report
 */
export const getReportSchedule = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof getReportScheduleMutator>,
  signal?: AbortSignal
) => {
  return getReportScheduleMutator<ReportSendScheduleSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportScheduleQueryKey = (projectId: string, reportId: string) => {
  return [`/project/${projectId}/report/${reportId}/schedule`] as const;
};

export const getGetReportScheduleQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportSchedule>>,
  TError = unknown
>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportSchedule>>, TError, TData>>;
    request?: SecondParameter<typeof getReportScheduleMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportScheduleQueryKey(projectId, reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportSchedule>>> = ({ signal }) =>
    getReportSchedule(projectId, reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && reportId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportSchedule>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportScheduleQueryResult = NonNullable<Awaited<ReturnType<typeof getReportSchedule>>>;
export type GetReportScheduleQueryError = unknown;

/**
 * @summary Gets the schedule of a report
 */
export const useGetReportSchedule = <TData = Awaited<ReturnType<typeof getReportSchedule>>, TError = unknown>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportSchedule>>, TError, TData>>;
    request?: SecondParameter<typeof getReportScheduleMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportScheduleQueryOptions(projectId, reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a report scheule to send the report to multiple users
 */
export const createReportSchedule = (
  projectId: string,
  reportId: string,
  reportSendScheduleCreateSchema: ReportSendScheduleCreateSchema,
  options?: SecondParameter<typeof createReportScheduleMutator>
) => {
  return createReportScheduleMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportSendScheduleCreateSchema
    },
    options
  );
};

export const getCreateReportScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReportSchedule>>,
    TError,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createReportScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReportSchedule>>,
  TError,
  { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReportSchedule>>,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return createReportSchedule(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReportScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof createReportSchedule>>>;
export type CreateReportScheduleMutationBody = ReportSendScheduleCreateSchema;
export type CreateReportScheduleMutationError = unknown;

/**
 * @summary Creates a report scheule to send the report to multiple users
 */
export const useCreateReportSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReportSchedule>>,
    TError,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createReportScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReportSchedule>>,
  TError,
  { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateReportScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates a report schedule
 */
export const updateReportSchedule = (
  projectId: string,
  reportId: string,
  reportSendScheduleCreateSchema: ReportSendScheduleCreateSchema,
  options?: SecondParameter<typeof updateReportScheduleMutator>
) => {
  return updateReportScheduleMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportSendScheduleCreateSchema
    },
    options
  );
};

export const getUpdateReportScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReportSchedule>>,
    TError,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateReportScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReportSchedule>>,
  TError,
  { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReportSchedule>>,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return updateReportSchedule(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReportScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof updateReportSchedule>>>;
export type UpdateReportScheduleMutationBody = ReportSendScheduleCreateSchema;
export type UpdateReportScheduleMutationError = unknown;

/**
 * @summary Updates a report schedule
 */
export const useUpdateReportSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReportSchedule>>,
    TError,
    { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateReportScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReportSchedule>>,
  TError,
  { projectId: string; reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const mutationOptions = getUpdateReportScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Sends a report to multiple users immediately
 */
export const sendReport = (
  projectId: string,
  reportId: string,
  params?: SendReportParams,
  options?: SecondParameter<typeof sendReportMutator>
) => {
  return sendReportMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/send`,
      method: 'POST',
      params
    },
    options
  );
};

export const getSendReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendReport>>,
    TError,
    { projectId: string; reportId: string; params?: SendReportParams },
    TContext
  >;
  request?: SecondParameter<typeof sendReportMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendReport>>,
  TError,
  { projectId: string; reportId: string; params?: SendReportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendReport>>,
    { projectId: string; reportId: string; params?: SendReportParams }
  > = (props) => {
    const { projectId, reportId, params } = props ?? {};

    return sendReport(projectId, reportId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendReportMutationResult = NonNullable<Awaited<ReturnType<typeof sendReport>>>;

export type SendReportMutationError = unknown;

/**
 * @summary Sends a report to multiple users immediately
 */
export const useSendReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendReport>>,
    TError,
    { projectId: string; reportId: string; params?: SendReportParams },
    TContext
  >;
  request?: SecondParameter<typeof sendReportMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendReport>>,
  TError,
  { projectId: string; reportId: string; params?: SendReportParams },
  TContext
> => {
  const mutationOptions = getSendReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Toggles the favorite status of a report
 */
export const toggleFavoriteReport = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof toggleFavoriteReportMutator>
) => {
  return toggleFavoriteReportMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/toggle-favorite`,
      method: 'PUT'
    },
    options
  );
};

export const getToggleFavoriteReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof toggleFavoriteReport>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof toggleFavoriteReportMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof toggleFavoriteReport>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof toggleFavoriteReport>>,
    { projectId: string; reportId: string }
  > = (props) => {
    const { projectId, reportId } = props ?? {};

    return toggleFavoriteReport(projectId, reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ToggleFavoriteReportMutationResult = NonNullable<Awaited<ReturnType<typeof toggleFavoriteReport>>>;

export type ToggleFavoriteReportMutationError = unknown;

/**
 * @summary Toggles the favorite status of a report
 */
export const useToggleFavoriteReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof toggleFavoriteReport>>,
    TError,
    { projectId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof toggleFavoriteReportMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof toggleFavoriteReport>>,
  TError,
  { projectId: string; reportId: string },
  TContext
> => {
  const mutationOptions = getToggleFavoriteReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revokes access to a report
 */
export const removeReportAccess = (
  projectId: string,
  reportId: string,
  reportAccessDeleteSchema: ReportAccessDeleteSchema,
  options?: SecondParameter<typeof removeReportAccessMutator>
) => {
  return removeReportAccessMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessDeleteSchema
    },
    options
  );
};

export const getRemoveReportAccessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessDeleteSchema },
    TContext
  >;
  request?: SecondParameter<typeof removeReportAccessMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessDeleteSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeReportAccess>>,
    { projectId: string; reportId: string; data: ReportAccessDeleteSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return removeReportAccess(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveReportAccessMutationResult = NonNullable<Awaited<ReturnType<typeof removeReportAccess>>>;
export type RemoveReportAccessMutationBody = ReportAccessDeleteSchema;
export type RemoveReportAccessMutationError = unknown;

/**
 * @summary Revokes access to a report
 */
export const useRemoveReportAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessDeleteSchema },
    TContext
  >;
  request?: SecondParameter<typeof removeReportAccessMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessDeleteSchema },
  TContext
> => {
  const mutationOptions = getRemoveReportAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the users that have access to a report
 */
export const getReportUsers = (
  projectId: string,
  reportId: string,
  options?: SecondParameter<typeof getReportUsersMutator>,
  signal?: AbortSignal
) => {
  return getReportUsersMutator<ReportAccessesSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportUsersQueryKey = (projectId: string, reportId: string) => {
  return [`/project/${projectId}/report/${reportId}/users`] as const;
};

export const getGetReportUsersQueryOptions = <TData = Awaited<ReturnType<typeof getReportUsers>>, TError = unknown>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportUsers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportUsersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportUsersQueryKey(projectId, reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportUsers>>> = ({ signal }) =>
    getReportUsers(projectId, reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && reportId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getReportUsers>>>;
export type GetReportUsersQueryError = unknown;

/**
 * @summary Gets the users that have access to a report
 */
export const useGetReportUsers = <TData = Awaited<ReturnType<typeof getReportUsers>>, TError = unknown>(
  projectId: string,
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportUsers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportUsersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportUsersQueryOptions(projectId, reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Grants access to a report for multiple users
 */
export const grantReportAccess = (
  projectId: string,
  reportId: string,
  reportAccessesCreateSchema: ReportAccessesCreateSchema,
  options?: SecondParameter<typeof grantReportAccessMutator>
) => {
  return grantReportAccessMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessesCreateSchema
    },
    options
  );
};

export const getGrantReportAccessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessesCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof grantReportAccessMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof grantReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessesCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof grantReportAccess>>,
    { projectId: string; reportId: string; data: ReportAccessesCreateSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return grantReportAccess(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GrantReportAccessMutationResult = NonNullable<Awaited<ReturnType<typeof grantReportAccess>>>;
export type GrantReportAccessMutationBody = ReportAccessesCreateSchema;
export type GrantReportAccessMutationError = unknown;

/**
 * @summary Grants access to a report for multiple users
 */
export const useGrantReportAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessesCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof grantReportAccessMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof grantReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessesCreateSchema },
  TContext
> => {
  const mutationOptions = getGrantReportAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates the access to a report
 */
export const updateReportAccess = (
  projectId: string,
  reportId: string,
  reportAccessCreateSchema: ReportAccessCreateSchema,
  options?: SecondParameter<typeof updateReportAccessMutator>
) => {
  return updateReportAccessMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessCreateSchema
    },
    options
  );
};

export const getUpdateReportAccessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateReportAccessMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReportAccess>>,
    { projectId: string; reportId: string; data: ReportAccessCreateSchema }
  > = (props) => {
    const { projectId, reportId, data } = props ?? {};

    return updateReportAccess(projectId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReportAccessMutationResult = NonNullable<Awaited<ReturnType<typeof updateReportAccess>>>;
export type UpdateReportAccessMutationBody = ReportAccessCreateSchema;
export type UpdateReportAccessMutationError = unknown;

/**
 * @summary Updates the access to a report
 */
export const useUpdateReportAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReportAccess>>,
    TError,
    { projectId: string; reportId: string; data: ReportAccessCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateReportAccessMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReportAccess>>,
  TError,
  { projectId: string; reportId: string; data: ReportAccessCreateSchema },
  TContext
> => {
  const mutationOptions = getUpdateReportAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report with all of its elements using token (public access)
 */
export const getPublicReport = (
  reportId: string,
  params: GetPublicReportParams,
  options?: SecondParameter<typeof getPublicReportMutator>,
  signal?: AbortSignal
) => {
  return getPublicReportMutator<ReportNestedSchema>(
    { url: `/public/report/${encodeURIComponent(String(reportId))}`, method: 'GET', params, signal },
    options
  );
};

export const getGetPublicReportQueryKey = (reportId: string, params: GetPublicReportParams) => {
  return [`/public/report/${reportId}`, ...(params ? [params] : [])] as const;
};

export const getGetPublicReportQueryOptions = <TData = Awaited<ReturnType<typeof getPublicReport>>, TError = unknown>(
  reportId: string,
  params: GetPublicReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicReport>>, TError, TData>>;
    request?: SecondParameter<typeof getPublicReportMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPublicReportQueryKey(reportId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicReport>>> = ({ signal }) =>
    getPublicReport(reportId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!reportId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPublicReportQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicReport>>>;
export type GetPublicReportQueryError = unknown;

/**
 * @summary Gets a report with all of its elements using token (public access)
 */
export const useGetPublicReport = <TData = Awaited<ReturnType<typeof getPublicReport>>, TError = unknown>(
  reportId: string,
  params: GetPublicReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicReport>>, TError, TData>>;
    request?: SecondParameter<typeof getPublicReportMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPublicReportQueryOptions(reportId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets a report element using token (public access)
 */
export const getPublicReportElement = (
  reportId: string,
  elementId: string,
  params: GetPublicReportElementParams,
  options?: SecondParameter<typeof getPublicReportElementMutator>,
  signal?: AbortSignal
) => {
  return getPublicReportElementMutator<ReportElementSchema>(
    {
      url: `/public/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetPublicReportElementQueryKey = (
  reportId: string,
  elementId: string,
  params: GetPublicReportElementParams
) => {
  return [`/public/report/${reportId}/element/${elementId}`, ...(params ? [params] : [])] as const;
};

export const getGetPublicReportElementQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicReportElement>>,
  TError = unknown
>(
  reportId: string,
  elementId: string,
  params: GetPublicReportElementParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicReportElement>>, TError, TData>>;
    request?: SecondParameter<typeof getPublicReportElementMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPublicReportElementQueryKey(reportId, elementId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicReportElement>>> = ({ signal }) =>
    getPublicReportElement(reportId, elementId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(reportId && elementId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicReportElement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPublicReportElementQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicReportElement>>>;
export type GetPublicReportElementQueryError = unknown;

/**
 * @summary Gets a report element using token (public access)
 */
export const useGetPublicReportElement = <TData = Awaited<ReturnType<typeof getPublicReportElement>>, TError = unknown>(
  reportId: string,
  elementId: string,
  params: GetPublicReportElementParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicReportElement>>, TError, TData>>;
    request?: SecondParameter<typeof getPublicReportElementMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPublicReportElementQueryOptions(reportId, elementId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
