import Loader from 'assets/loading.svg?react';
import Trash from 'assets/trash-remove.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { useGetReports } from 'lib/report/report';
import { memo, useState } from 'react';
import DeleteUserDialog from './DeleteUserDialog';

function DeleteUserButton() {
  const { project } = useActiveProject();
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const { data: reports, isFetching: reportsFetching } = useGetReports(
    project.id!,
    { sharing_type: 'my_reports' },
    { query: { queryKey: ['currentUserReports'], refetchOnWindowFocus: false } }
  );

  if (reportsFetching) {
    return <Loader aria-hidden="true" className="size-5 animate-spin fill-red-500" />;
  }

  const hasReports = Boolean(reports?.objects && reports.objects.length > 0);

  function deleteAccount() {
    setDeleteAccountOpen(true);
  }

  return (
    <>
      <DeleteUserDialog open={deleteAccountOpen} setOpen={setDeleteAccountOpen} hasReports={hasReports} />
      <div className="flex cursor-pointer gap-2" onClick={deleteAccount}>
        <Trash width={20} height={20} className="fill-red-500" />
        <span className="text-sm font-medium text-red-500">Delete Account</span>
      </div>
    </>
  );
}

export default memo(DeleteUserButton);
