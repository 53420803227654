import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReportElementTemplatesSchema, ReportNestedSchema } from 'lib/model';

import useActiveProject from 'contexts/project/projectContext';
import { getGetLastEditedReportsQueryKey, useCreateReportElement } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DialogProps, ValueOption } from 'utils/interfaces';
import { elementTemplateToValueOption } from 'utils/mappings';
import ElementForm from './elementForms/ElementForm';
import { ElementFormValues } from './elementForms/interfaces';
import ElementTemplateDescription from './ElementTemplateDescription';
import { transformAttributeValues } from './helpers';

interface AddElementStaticFormValues {
  elementTemplate: ValueOption | null;
}

interface AddElementDialogProps extends DialogProps {
  report: ReportNestedSchema;
  elementTemplates: ReportElementTemplatesSchema;
}

const AddElementDialog = memo(function AddElementDialog({
  open,
  setOpen,
  report,
  elementTemplates
}: AddElementDialogProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const { mutate: createReportElement, isPending: isCreating } = useCreateReportElement({
    mutation: {
      onMutate: async () => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        return { loadingToastId };
      },
      onError: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
      },
      onSuccess: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
      },
      onSettled: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['project', project.id!, 'reports', report.id!]
        });
        queryClient.invalidateQueries({
          exact: true,
          queryKey: getGetLastEditedReportsQueryKey(project.id!)
        });
      }
    }
  });

  const elementTemplateOptions = useMemo(
    () => (!report || !elementTemplates ? [] : elementTemplates?.objects!.map(elementTemplateToValueOption)),
    [elementTemplates, report]
  );

  const { watch, control: elementTemplateControl } = useForm<AddElementStaticFormValues>({
    defaultValues: {
      elementTemplate: null
    }
  });
  const selectedElementTemplateOption = watch('elementTemplate');
  const selectedElementTemplate = useMemo(
    () => elementTemplates?.objects?.find((template) => template.id === selectedElementTemplateOption?.id),
    [elementTemplates, selectedElementTemplateOption]
  );

  const addElement = useCallback(
    function addElement(data: ElementFormValues) {
      if (!selectedElementTemplate) {
        return;
      }
      const attributeValues = transformAttributeValues(data, selectedElementTemplate.attribute_choices);

      createReportElement({
        projectId: project!.id!,
        reportId: report.id!,
        data: {
          report_element_template: selectedElementTemplate!.id!,
          attribute_values: attributeValues
        }
      });
    },
    [createReportElement, report.id, selectedElementTemplate]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title="Add Element" onCancel={handleCancel} open={open}>
      <DialogContent>
        <div className="flex flex-col gap-6">
          {elementTemplateOptions && (
            <div className="flex flex-col gap-3">
              <SelectInput
                formProps={{
                  name: 'elementTemplate',
                  control: elementTemplateControl
                }}
                label="Choose element"
                options={elementTemplateOptions}
              />
              {selectedElementTemplate && <ElementTemplateDescription template={selectedElementTemplate} />}
            </div>
          )}
          {selectedElementTemplate && selectedElementTemplate.is_available && (
            <>
              <ElementForm
                key={selectedElementTemplate.id}
                onSubmitForm={addElement}
                report={report}
                template={selectedElementTemplate}
              />
            </>
          )}
          {selectedElementTemplate && !selectedElementTemplate.is_available && (
            <span className="text-red-500">
              Unfortunately, this element is not available for this report type. Please acquire additional data sources
              to use this element.
            </span>
          )}
        </div>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel} disabled={isCreating}>
          <span>Cancel</span>
        </Button>
        <Button
          size="xl"
          isSubmitButton
          form="new-element-form"
          loading={isCreating}
          disabled={!selectedElementTemplate?.is_available}
        >
          <span>Add Element</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default AddElementDialog;
