import { NavLinkProps, NavLink as ReactRouterNavLink } from 'react-router-dom';

interface Props extends NavLinkProps {
  disabled?: boolean;
}
function NavLink({ disabled, children, ...rest }: Props) {
  function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    if (disabled) {
      e.preventDefault();
    }
  }

  return (
    <ReactRouterNavLink {...rest} onClick={onClick}>
      {children}
    </ReactRouterNavLink>
  );
}

export default NavLink;
