/* eslint-disable react/jsx-no-bind */
import { curveMonotoneX } from '@visx/curve';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { max, min } from '@visx/vendor/d3-array';
import { getReportElementColor } from 'modules/reports/colors';
import { memo, useCallback, useMemo, useRef } from 'react';
import { ChartEntityTooltip } from '../../charts/ChartEntityTooltip';
import ChartAxes from '../ChartAxes';
import ChartTooltip from '../ChartTooltip';
import { BaseReportElementProps } from '../interfaces';
import ChartLinePath from './components/ChartLinePath';
import ChartPoint from './components/ChartPoint';
import { LineChartPoint } from './interfaces';

interface LineChartProps extends BaseReportElementProps {
  events: LineChartPoint[];
  competitionEvents?: LineChartPoint[];
  groupByKey: 'player_id' | 'team_id' | 'competition_id';
  xType?: 'number' | 'date' | 'category';
  xName: string;
  yName: string;
}

function reduceLineData(
  acc: Record<string, LineChartPoint[]>,
  curr: LineChartPoint,
  xType: string,
  groupByKey: 'player_id' | 'team_id' | 'competition_id'
) {
  if (xType === 'date') {
    curr.x_value = new Date(curr.x_value);
  }
  if (!acc[curr[groupByKey]]) {
    acc[curr[groupByKey]] = [];
  }
  acc[curr[groupByKey]].push(curr);
  return acc;
}

const LineChart = memo(function LineChart({
  events,
  competitionEvents,
  groupByKey,
  xType = 'number',
  xName = 'Minutes',
  yName = 'Value',
  report,
  element
}: LineChartProps) {
  const lineData = useMemo(() => {
    if (!events) {
      return undefined;
    }
    const entityData = events.reduce(
      (acc: Record<string, LineChartPoint[]>, point) => reduceLineData(acc, point, xType, groupByKey),
      {}
    );
    const competitionData = competitionEvents?.reduce(
      (acc: Record<string, LineChartPoint[]>, point) => reduceLineData(acc, point, xType, 'competition_id'),
      {}
    );
    return { entityData, competitionData };
  }, [events, competitionEvents, groupByKey, xType]);

  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  const height = 350;
  const margin = {
    top: 0,
    right: 0,
    bottom: 50,
    left: 50
  };
  const chartOffset = 20;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const getX = useCallback((point: LineChartPoint) => point.x_value, []);
  const getY = useCallback((point: LineChartPoint) => point.y_value, []);

  const yScale = useMemo(() => {
    const allEvents = [...events, ...(competitionEvents ?? [])];
    return scaleLinear<number>({
      domain: [
        Math.min(0, min(Object.values(allEvents), getY as (point: LineChartPoint) => number)!),
        allEvents.length > 0
          ? max(
              Object.values(allEvents).flatMap((values) => values),
              getY
            )!
          : 100
      ],
      round: true
    });
  }, [events, competitionEvents, getY]);

  const xScale = useMemo(() => {
    const allEvents = [...events, ...(competitionEvents ?? [])];
    if (xType === 'number') {
      return scaleLinear<number>({
        domain: [
          0,
          allEvents.length > 0
            ? Math.max(max(Object.values(allEvents), getX as (point: LineChartPoint) => number)!, 90)
            : 90
        ],
        round: true
      });
    } else if (xType === 'date') {
      let earliestDate = new Date(events[0].x_value as string);
      let latestDate = new Date(events[events.length - 1].x_value as string);
      if (competitionEvents && competitionEvents.length > 0) {
        const competitionEarliestDate = new Date(competitionEvents[0].x_value as string);
        const competitionLatestDate = new Date(competitionEvents[competitionEvents.length - 1].x_value as string);
        latestDate = latestDate > competitionLatestDate ? latestDate : competitionLatestDate;
        earliestDate = earliestDate < competitionEarliestDate ? earliestDate : competitionEarliestDate;
      }
      return scaleTime<number>({
        domain: [earliestDate, latestDate],
        nice: true
      });
    } else {
      // xType === 'category'
      return scaleBand<string>({
        domain:
          allEvents.length > 0
            ? Array.from(new Set(Object.values(allEvents).map((value) => value.x_value) as string[]))
            : [],
        paddingOuter: 0,
        paddingInner: 1
      });
    }
  }, [events, competitionEvents, getX, xType]);

  const xValue = useCallback(
    function xValue(point: LineChartPoint) {
      // TypeScript doesn't realize that right type will be used for each of scales
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return xScale(getX(point) as any) ?? 0;
    },
    [getX, xScale]
  );

  const yValue = useCallback(
    function yValue(point: LineChartPoint) {
      return yScale(getY(point)) ?? 0;
    },
    [getY, yScale]
  );

  // domain -> range === value -> "pixels" mapping
  xScale.range([chartOffset, chartWidth - chartOffset]);
  yScale.range([chartHeight - chartOffset, chartOffset]);

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } = useTooltip<LineChartPoint>({
    tooltipOpen: false
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleMouseEnter = useCallback(
    function handleMouseEnter(point: LineChartPoint) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        showTooltip({
          tooltipData: point,
          tooltipLeft: xValue(point) + 40,
          tooltipTop: yValue(point) - 20 - 16
        });
      };
    },
    [showTooltip, xValue, yValue]
  );

  return (
    <div ref={parentRef} className="relative flex flex-col gap-6">
      <svg height={height} className="w-full overflow-x-visible" ref={containerRef}>
        <rect width={chartWidth} height={chartHeight} x={margin.left} y={margin.top} fill="#f3f4f6" rx={12} ry={12} />
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={chartWidth}
            height={chartHeight}
            strokeDasharray="2,2"
            stroke="#D1D5DB"
            strokeWidth={1}
          />
          <ChartAxes
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            xScale={xScale}
            yScale={yScale}
            xUnit={xName}
            yUnit={yName}
            xType={xType}
          />
          {lineData?.entityData &&
            Object.entries(lineData.entityData).map(([id, points]) => {
              const color = points[0].team_color
                ? points[0].team_color
                : getReportElementColor(report, element, { teamId: points[0].team_id, playerId: points[0].player_id });

              return (
                <ChartLinePath
                  key={`entity-${id}-path`}
                  points={points}
                  color={color}
                  curve={curveMonotoneX}
                  x={xValue}
                  y={yValue}
                />
              );
            })}
          {lineData?.entityData &&
            Object.entries(lineData.entityData).flatMap(([id, points]) =>
              points.map((point, i) => {
                const color = points[0].team_color
                  ? points[0].team_color
                  : getReportElementColor(report, element, {
                      teamId: points[0].team_id,
                      playerId: points[0].player_id
                    });

                return (
                  <ChartPoint
                    key={`entity-${id}-${i}`}
                    point={point}
                    color={color}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    cx={xScale(getX(point) as any)}
                    cy={yScale(getY(point))}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                  />
                );
              })
            )}
          {lineData?.competitionData &&
            Object.entries(lineData.competitionData).map(([id, points], index) => {
              const color = getReportElementColor(report, element, {
                competitionId: points[0].competition_id,
                isCompetition: true
              });

              return (
                <ChartLinePath
                  key={`competition-${id}-path`}
                  points={points}
                  color={color}
                  curve={curveMonotoneX}
                  x={xValue}
                  y={yValue}
                  className="[stroke-dasharray:2,5]"
                />
              );
            })}
          {lineData?.competitionData &&
            Object.entries(lineData.competitionData).flatMap(([id, points], index) =>
              points.map((point, i) => {
                const color = getReportElementColor(report, element, {
                  competitionId: points[0].competition_id,
                  isCompetition: true
                });
                return (
                  <ChartPoint
                    key={`competition-${id}-${i}`}
                    point={point}
                    color={color}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    cx={xScale(getX(point) as any)}
                    cy={yScale(getY(point))}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                  />
                );
              })
            )}
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        <ChartEntityTooltip entity={tooltipData} chart="line" xName={xName} yName={yName} />
      </ChartTooltip>
    </div>
  );
});

export default LineChart;
