import { getLeagueReportColor } from 'modules/reports/colors';
import { isLightColor } from 'utils/helpers';
import { LeagueGeneralData, ReportTypeHeader } from '../interfaces';

import { twJoin } from 'tailwind-merge';
import LeagueIcon from '../../LeagueIcon';

interface LeagueHeaderProps extends ReportTypeHeader {
  league: LeagueGeneralData;
}

const LeagueHeader = ({ league, textSize = 'sm', iconSize = 'md', narrower = false }: LeagueHeaderProps) => {
  const color = getLeagueReportColor(league, { isCompetition: true });
  const isLight = isLightColor(color);
  return (
    <div
      className={twJoin('flex flex-col items-center gap-3 rounded-xl px-3', narrower ? 'py-3' : 'py-6')}
      style={{ backgroundColor: color }}
    >
      <LeagueIcon color="white" size={iconSize} shadow />
      <span
        className={twJoin(
          'text-center font-semibold',
          textSize === 'tiny' && 'text-tiny',
          textSize === 'sm' && 'text-sm',
          textSize === 'lg' && 'text-lg',
          isLight ? 'text-black' : 'text-white'
        )}
      >
        {league.competition_name}
      </span>
    </div>
  );
};

export default LeagueHeader;
