import { memo } from 'react';
import { BaseReportElementProps } from '../../interfaces';
import MatchLineupPitch from './variations/MatchLineupPitch';
import TeamComparisonLineupPitch from './variations/TeamComparisonLineupPitch';
import TeamLineupPitch from './variations/TeamLineupPitch';

const LineupPitchElement = memo(function LineupPitchElement({ report, element }: BaseReportElementProps) {
  if (report.report_type === 'match') {
    return <MatchLineupPitch report={report} element={element} />;
  } else if (report.report_type === 'team') {
    return <TeamLineupPitch report={report} element={element} />;
  } else if (report.report_type === 'team_comparison') {
    return <TeamComparisonLineupPitch report={report} element={element} />;
  }

  return null;
});

export default LineupPitchElement;
