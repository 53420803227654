/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getAdminDatasourceConfigurationsLatestMutator,
  default as getAdminDeploymentsLatestMutator,
  default as getAdminProjectsAwsCostMutator,
  default as refreshReportsCacheMutator
} from '../../api/axios';
import type { DatasourceConfigurationsNestedSchema, ProjectAwsCostsSchema, ProjectDeploymentsSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get the cost of all projects
 */
export const getAdminProjectsAwsCost = (
  options?: SecondParameter<typeof getAdminProjectsAwsCostMutator>,
  signal?: AbortSignal
) => {
  return getAdminProjectsAwsCostMutator<ProjectAwsCostsSchema>(
    { url: `/admin/aws-cost`, method: 'GET', signal },
    options
  );
};

export const getGetAdminProjectsAwsCostQueryKey = () => {
  return [`/admin/aws-cost`] as const;
};

export const getGetAdminProjectsAwsCostQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminProjectsAwsCost>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminProjectsAwsCost>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminProjectsAwsCostMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminProjectsAwsCostQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminProjectsAwsCost>>> = ({ signal }) =>
    getAdminProjectsAwsCost(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminProjectsAwsCost>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminProjectsAwsCostQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminProjectsAwsCost>>>;
export type GetAdminProjectsAwsCostQueryError = unknown;

/**
 * @summary Get the cost of all projects
 */
export const useGetAdminProjectsAwsCost = <
  TData = Awaited<ReturnType<typeof getAdminProjectsAwsCost>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminProjectsAwsCost>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminProjectsAwsCostMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminProjectsAwsCostQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get latest datasource configurations for all projects
 */
export const getAdminDatasourceConfigurationsLatest = (
  options?: SecondParameter<typeof getAdminDatasourceConfigurationsLatestMutator>,
  signal?: AbortSignal
) => {
  return getAdminDatasourceConfigurationsLatestMutator<DatasourceConfigurationsNestedSchema>(
    { url: `/admin/datasource-configuration/latest`, method: 'GET', signal },
    options
  );
};

export const getGetAdminDatasourceConfigurationsLatestQueryKey = () => {
  return [`/admin/datasource-configuration/latest`] as const;
};

export const getGetAdminDatasourceConfigurationsLatestQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminDatasourceConfigurationsLatestMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminDatasourceConfigurationsLatestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>> = ({ signal }) =>
    getAdminDatasourceConfigurationsLatest(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminDatasourceConfigurationsLatestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>
>;
export type GetAdminDatasourceConfigurationsLatestQueryError = unknown;

/**
 * @summary Get latest datasource configurations for all projects
 */
export const useGetAdminDatasourceConfigurationsLatest = <
  TData = Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminDatasourceConfigurationsLatest>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminDatasourceConfigurationsLatestMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminDatasourceConfigurationsLatestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get latest project deployments for all projects
 */
export const getAdminDeploymentsLatest = (
  options?: SecondParameter<typeof getAdminDeploymentsLatestMutator>,
  signal?: AbortSignal
) => {
  return getAdminDeploymentsLatestMutator<ProjectDeploymentsSchema>(
    { url: `/admin/deployment/latest`, method: 'GET', signal },
    options
  );
};

export const getGetAdminDeploymentsLatestQueryKey = () => {
  return [`/admin/deployment/latest`] as const;
};

export const getGetAdminDeploymentsLatestQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminDeploymentsLatest>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminDeploymentsLatest>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminDeploymentsLatestMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminDeploymentsLatestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminDeploymentsLatest>>> = ({ signal }) =>
    getAdminDeploymentsLatest(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminDeploymentsLatest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminDeploymentsLatestQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminDeploymentsLatest>>>;
export type GetAdminDeploymentsLatestQueryError = unknown;

/**
 * @summary Get latest project deployments for all projects
 */
export const useGetAdminDeploymentsLatest = <
  TData = Awaited<ReturnType<typeof getAdminDeploymentsLatest>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminDeploymentsLatest>>, TError, TData>>;
  request?: SecondParameter<typeof getAdminDeploymentsLatestMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminDeploymentsLatestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Refreshes all reports cache data
 */
export const refreshReportsCache = (
  options?: SecondParameter<typeof refreshReportsCacheMutator>,
  signal?: AbortSignal
) => {
  return refreshReportsCacheMutator<void>({ url: `/admin/refresh-cache`, method: 'GET', signal }, options);
};

export const getRefreshReportsCacheQueryKey = () => {
  return [`/admin/refresh-cache`] as const;
};

export const getRefreshReportsCacheQueryOptions = <
  TData = Awaited<ReturnType<typeof refreshReportsCache>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof refreshReportsCache>>, TError, TData>>;
  request?: SecondParameter<typeof refreshReportsCacheMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRefreshReportsCacheQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof refreshReportsCache>>> = ({ signal }) =>
    refreshReportsCache(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof refreshReportsCache>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RefreshReportsCacheQueryResult = NonNullable<Awaited<ReturnType<typeof refreshReportsCache>>>;
export type RefreshReportsCacheQueryError = unknown;

/**
 * @summary Refreshes all reports cache data
 */
export const useRefreshReportsCache = <
  TData = Awaited<ReturnType<typeof refreshReportsCache>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof refreshReportsCache>>, TError, TData>>;
  request?: SecondParameter<typeof refreshReportsCacheMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRefreshReportsCacheQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
