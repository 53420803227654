import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Info from 'assets/info.svg?react';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { PlayerQuerySchema, ReportCreateSchemaReportType } from 'lib/model';
import { useCreateReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useState } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SCOUT_REPORT_VARIANT } from '../constants';
import { scoutFormToScoutSettings } from '../helpers';
import { ScoutReportFormValues } from '../interfaces';
import ScoutReportAddManualPlayer from '../ScoutReportAddManualPlayer';
import ScoutReportManualPlayerList from '../ScoutReportManualPlayerList';
import ScoutReportFilteredPlayerList from './ScoutReportFilteredPlayerList';

interface ScoutReportFilteredFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onBack: () => void;
}

function SimilarPlayersOverview({ formData, totalPlayers }: { formData: ScoutReportFormValues; totalPlayers: number }) {
  const player = formData.similarPlayer!.value as PlayerQuerySchema;
  const metrics = formData.similarMetrics ?? [];
  const metricsExist = metrics.length > 0;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <span className="text-md font-medium">Players: {totalPlayers}</span>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-sm">
          Looking for players similar to <b className="font-semibold">{player.player_name}</b>, based on the following
          metrics:
        </p>
        {metricsExist ? (
          <div className="text-sm">{metrics?.map((metric) => metric.label).join(', ')}</div>
        ) : (
          <div className="text-sm font-semibold">All metrics</div>
        )}
      </div>
    </div>
  );
}

function ScoutReportFilteredForm({ onBack, scoutReportForm }: ScoutReportFilteredFormProps) {
  const [isAddingPlayer, setIsAddingPlayer] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const formData = useWatch(scoutReportForm) as ScoutReportFormValues;
  const formState = useFormState(scoutReportForm);
  const totalPlayers = (formData.players?.length ?? 0) + (Number(formData.numberOfFilteredPlayers) ?? 0);

  const isLoadingPlayers = useIsFetching({ queryKey: ['filtered-players'] }) !== 0;

  const { mutate: createReport, isPending: isCreating } = useCreateReport();
  const handleCreateReport = useCallback(
    function handleCreateReport(data: ScoutReportFormValues) {
      const scoutReportSettings = scoutFormToScoutSettings(data);

      createReport(
        {
          projectId: project.id!,
          data: {
            name: data.reportName!,
            report_type: ReportCreateSchemaReportType.scout,
            project: project!.id!,
            report_entities: data.similarPlayer
              ? [(data.similarPlayer.value as PlayerQuerySchema).player_id as number]
              : data.players.map((player) => player.player_id as number),
            scout_report_settings: scoutReportSettings
          }
        },
        {
          onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['project', project.id!, 'reports'] });
            navigate(`/reports/${res.id!}`);
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              scoutReportForm.setError('root', { message: err.response?.data.error, type: 'backend-validation' });
            }
          }
        }
      );
    },
    [createReport, navigate, project, scoutReportForm]
  );

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="manual-scout-report-form"
          onSubmit={scoutReportForm.handleSubmit(handleCreateReport)}
        >
          <span className="text-md font-medium">Report name</span>
          <Input
            label="Enter report name"
            registerReturn={{
              ...scoutReportForm.register('reportName', {
                required: { value: true, message: 'Report name is required.' }
              })
            }}
            error={formState.errors.reportName}
          />
          {formState.errors?.root && <ErrorMessage error={formState.errors.root} />}
        </form>
        {formData.variant.id === SCOUT_REPORT_VARIANT.ranking && (
          <>
            <div className="flex flex-col gap-1">
              <span className="text-md font-medium">Players: {totalPlayers}</span>
              <p className="text-sm">You can add up to maximum of 20 players in your report.</p>
            </div>
            <ScoutReportFilteredPlayerList scoutReportForm={scoutReportForm} />
            <div className="flex flex-col gap-6">
              <span className="text-md font-medium">Manually added players</span>
              <div className="flex gap-2 rounded-md bg-brand-50 px-6 py-4">
                <Info className="size-5 fill-brand-800" />
                <p className="text-sm text-brand-800">
                  Manually added players will always stay in the report, regardless of data updates.
                </p>
              </div>
            </div>
            <ScoutReportManualPlayerList scoutReportForm={scoutReportForm} />
            <ScoutReportAddManualPlayer
              scoutReportForm={scoutReportForm}
              currentPlayers={formData.players}
              position={formData.position}
              totalPlayers={totalPlayers}
              isAddingPlayer={isAddingPlayer}
              setIsAddingPlayer={setIsAddingPlayer}
            />
          </>
        )}
        {formData.variant.id === SCOUT_REPORT_VARIANT.similar && (
          <SimilarPlayersOverview formData={formData} totalPlayers={totalPlayers} />
        )}
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack} disabled={isCreating}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button
          size="xl"
          isSubmitButton
          form="manual-scout-report-form"
          loading={isCreating}
          disabled={totalPlayers === 0 || isAddingPlayer || isLoadingPlayers}
        >
          <span>Create Scout Report</span>
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportFilteredForm);
