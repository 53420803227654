import useActiveProject from 'contexts/project/projectContext';
import { useGetFavoriteReports } from 'lib/report/report';
import { memo } from 'react';
import ReportsGrid from './ReportGrid';

const FavoriteReports = memo(function FavoriteReports() {
  const { project } = useActiveProject();
  const { data: favoriteReports, isFetching: isFavoriteReportsPending } = useGetFavoriteReports(project.id!, {
    query: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false
    }
  });

  if (!isFavoriteReportsPending && favoriteReports?.objects?.length === 0) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-sm font-semibold">Favorites</span>
      <ReportsGrid data={favoriteReports} isDataFetching={isFavoriteReportsPending} />
    </div>
  );
});

export default FavoriteReports;
