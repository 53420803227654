import SyncStatusEnum from 'constants/enums/syncStatus';
import useAuth from 'contexts/auth/authContext';
import {
  useGetAdminDatasourceConfigurationsLatest,
  useGetAdminDeploymentsLatest,
  useGetAdminProjectsAwsCost
} from 'lib/admin/admin';

import Spinner from 'assets/loading.svg?react';
import StatusEnum from 'constants/enums/status';
import { ProjectAwsCost } from 'lib/model';
import { useMemo } from 'react';
import AwsCosts from './AwsCosts';
import StatusIcon from './StatusIcon';

const StatusSkeleton = () => {
  return <div className="h-6 w-full animate-pulse rounded-xl bg-gray-100" />;
};

const iconSize = 24;
const DashboardAdminSummary = () => {
  const { user } = useAuth();

  ////////////////////////////////////
  ///////     DEPLOYMENTS
  ////////////////////////////////////
  const { data: deploymentsResponse, isFetching: fetchingDeployments } = useGetAdminDeploymentsLatest({
    query: { queryKey: ['admin', 'deployments', 'latest'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  const latestDeployments = useMemo(() => {
    return deploymentsResponse?.objects ?? [];
  }, [deploymentsResponse]);

  const deploymentStatus = useMemo(() => {
    const deploymentStatus = {
      FAILED: 0,
      SUCCESS: 0,
      RUNNING: 0,
      UNKNOWN: 0
    };

    latestDeployments.forEach((deployment) => {
      if (deployment.exit_status === 'FAILED') {
        deploymentStatus.FAILED += 1;
      } else if (deployment.exit_status === 'SUCCEEDED') {
        deploymentStatus.SUCCESS += 1;
      } else if (!deployment.time_finished) {
        deploymentStatus.RUNNING += 1;
      } else {
        deploymentStatus.UNKNOWN += 1;
      }
    });
    return deploymentStatus;
  }, [latestDeployments]);

  ////////////////////////////////////
  ///////     DATA SOURCES
  ////////////////////////////////////

  const { data: datasourceConfigurationResponse, isFetching: fetchingDatasourceConfigurations } =
    useGetAdminDatasourceConfigurationsLatest({
      query: {
        queryKey: ['admin', 'datasource-configuration', 'latest'],
        enabled: !!user.is_admin,
        staleTime: Infinity
      }
    });
  const datasourceConfigurations = useMemo(() => {
    return datasourceConfigurationResponse?.objects ?? [];
  }, [datasourceConfigurationResponse]);

  const datasourceStatus = useMemo(() => {
    const datasourceStatus = {
      FAILED: 0,
      SUCCESS: 0,
      RUNNING: 0,
      UNKNOWN: 0
    };

    datasourceConfigurations.forEach((datasource) => {
      switch (datasource.datasource_configuration_status?.status) {
        case SyncStatusEnum.FAILED:
          datasourceStatus.FAILED += 1;
          break;
        case SyncStatusEnum.SUCCESSFUL:
          datasourceStatus.SUCCESS += 1;
          break;
        case SyncStatusEnum.IN_PROGRESS:
        case SyncStatusEnum.DEPLOYING:
          datasourceStatus.RUNNING += 1;
          break;
        default:
          datasourceStatus.UNKNOWN += 1;
          break;
      }
    });

    return datasourceStatus;
  }, [datasourceConfigurations]);

  ////////////////////////////////////
  ///////     AWS
  ////////////////////////////////////

  // TODO - Add AWS status
  const { data: projectCostsData } = useGetAdminProjectsAwsCost({
    query: { queryKey: ['admin', 'projects', 'aws-cost'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  const projectCosts = useMemo(() => {
    const awsCosts = projectCostsData?.objects ?? [];
    const costsSum = awsCosts.reduce(
      (acc, aws: ProjectAwsCost) => {
        const cost = aws.aws_cost;
        if (!cost) {
          return acc;
        }

        for (const [key, value] of Object.entries(cost)) {
          if (!acc[key]) {
            acc[key] = Number(value as string);
          } else {
            acc[key] += Number(value as string);
          }
        }
        return acc;
      },
      {} as { [key: string]: number }
    );
    return { currentCost: costsSum.current_month_cost_USD ?? 0, lastMonthCost: costsSum.past_month_cost_USD ?? 0 };
  }, [projectCostsData]);

  ////////////////////////////////////
  ///////     STATUS
  ////////////////////////////////////
  const { status, statusDescription } = useMemo(() => {
    if (deploymentStatus.FAILED > 0 || datasourceStatus.FAILED > 0) {
      return { status: StatusEnum.ERROR, statusDescription: 'An error was detected.' };
    }
    return { status: StatusEnum.SUCCESS, statusDescription: 'No error detected.' };
  }, [deploymentStatus, datasourceStatus]);

  return (
    <div className={'flex items-center gap-6 rounded-xl bg-gray-50 p-6 max-sm:flex-col'}>
      <div className="flex min-w-[160px] flex-col items-center gap-2 text-center">
        {fetchingDeployments || fetchingDatasourceConfigurations ? (
          <Spinner className="size-32 animate-spin fill-brand-800" />
        ) : (
          <>
            <StatusIcon status={status} iconSize={128} />
            <div className="text-sm font-semibold">{statusDescription}</div>
          </>
        )}
      </div>
      <div className="flex gap-8 max-md:flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div>
              <h5 className="text-sm font-semibold text-gray-600">Deployments ({latestDeployments.length})</h5>
            </div>
            {fetchingDeployments ? (
              <StatusSkeleton />
            ) : (
              <div className="flex gap-4 text-sm font-semibold">
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.ERROR} iconSize={iconSize} /> {deploymentStatus.FAILED}
                </div>
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.RUNNING} iconSize={iconSize} /> {deploymentStatus.RUNNING}
                </div>
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.SUCCESS} iconSize={iconSize} /> {deploymentStatus.SUCCESS}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <h5 className="text-sm font-semibold text-gray-600">Data Sources ({datasourceConfigurations.length})</h5>
            {fetchingDatasourceConfigurations ? (
              <StatusSkeleton />
            ) : (
              <div className="flex gap-4 text-sm font-semibold">
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.ERROR} iconSize={iconSize} /> {datasourceStatus.FAILED}
                </div>
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.RUNNING} iconSize={iconSize} /> {datasourceStatus.RUNNING}
                </div>
                <div className="flex items-center gap-1">
                  <StatusIcon status={StatusEnum.SUCCESS} iconSize={iconSize} /> {datasourceStatus.SUCCESS}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <h5 className="text-sm font-semibold text-gray-600">AWS MONTHLY COSTS</h5>
          <div className="text-sm">
            <AwsCosts currentCost={projectCosts.currentCost} previousCost={projectCosts.lastMonthCost} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdminSummary;
