import { getReportElementColor } from 'modules/reports/colors';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor, snakeCaseToWords } from 'utils/helpers';
import { BaseReportElementProps } from '../interfaces';

interface VersusResult {
  type: string;
  match_id: number;
  home_team_value: number;
  away_team_value: number;
  // max_value: number; // so we know how much % to fill up the bar
}

interface VersusData {
  home_team_id: number;
  home_team_name: string;
  home_team_color?: string;
  away_team_id: number;
  away_team_name: string;
  away_team_color?: string;
  results: VersusResult[];
}

function calculateWidth(home: number, away: number): string {
  if (home === 0 && away === 0) return '50%';
  const absHome = Math.abs(home);
  const absAway = Math.abs(away);
  const total = absHome + absAway;
  const percentage = (absHome / total) * 100;
  return `${percentage}%`;
}

const VersusElement = memo(function VersusElement({ report, element }: BaseReportElementProps) {
  const data = element.entity_data as VersusData;
  data.home_team_color ??= getReportElementColor(report, element, { teamId: data.home_team_id });
  data.away_team_color ??= getReportElementColor(report, element, { teamId: data.away_team_id });
  const isHomeLight = isLightColor(data.home_team_color);
  const isAwayLight = isLightColor(data.away_team_color);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between gap-6">
        <span className="text-xs font-semibold">{data.home_team_name}</span>
        <span className="text-xs font-semibold">{data.away_team_name}</span>
      </div>
      <div className="flex flex-col gap-2">
        {data.results.map((result) => {
          return (
            <div key={result.type} className="flex flex-col items-center gap-3 rounded-md bg-gray-50 p-3">
              <span className="text-xs font-medium">{snakeCaseToWords(result.type)}</span>
              <div className="relative flex w-full items-center justify-between px-3">
                <span className={twJoin('z-10 text-xs font-bold', isHomeLight ? '' : 'text-white')}>
                  {result.home_team_value}
                </span>
                <span className={twJoin('z-10 text-xs font-bold', isAwayLight ? '' : 'text-white')}>
                  {result.away_team_value}
                </span>
                <div
                  className={twJoin(
                    'absolute left-[-1px] z-[1] h-6 w-full rounded-full',
                    isHomeLight && 'border border-gray-300'
                  )}
                  style={{
                    backgroundColor: data.home_team_color,
                    width: calculateWidth(result.home_team_value, result.away_team_value)
                  }}
                />
                <div
                  className={twJoin('absolute left-0 h-6 w-full rounded-full', isAwayLight && 'border border-gray-300')}
                  style={{ backgroundColor: data.away_team_color }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default VersusElement;
