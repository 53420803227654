import Report from 'assets/report.svg?react';
import useAuth from 'contexts/auth/authContext';
import { memo, useMemo } from 'react';

import { DEFAULT_LAST_EDITED_REPORTS_COUNT } from 'constants/reports';
import useActiveProject from 'contexts/project/projectContext';
import { ReportSchema } from 'lib/model';
import { useGetLastEditedReports } from 'lib/report/report';
import ReportCard from 'modules/reports/reports/components/reportCard/ReportCard';
import { NavLink } from 'react-router-dom';
import DashboardCardsSkeleton from '../DashboardCardsSkeleton';
/*
TODO: add demo reports if free trial project?
*/

function DashboardReports() {
  const { project } = useActiveProject();
  const { isViewer } = useAuth();
  // TODO: insert demo reports here (1st in order)
  const { data: lastEditedReports, isFetching: isLastEditedReportsPending } = useGetLastEditedReports(
    project.id!,
    {
      num_last_edited_reports: DEFAULT_LAST_EDITED_REPORTS_COUNT
    },
    {
      query: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false
      }
    }
  );

  const reports: ReportSchema[] = useMemo(() => {
    if (isViewer) {
      // TODO: add recently viewed reports
      return lastEditedReports?.objects ?? [];
    } else {
      return lastEditedReports?.objects ?? [];
    }
  }, [isViewer, lastEditedReports]);

  if (isLastEditedReportsPending) {
    return <DashboardCardsSkeleton />;
  }

  const title = isViewer ? 'Recently viewed reports' : 'Recently edited reports';
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <Report className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">{title}</span>
        </div>
        <div className="flex gap-4">
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/reports?ownership=my_reports'}>
            My reports
          </NavLink>
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/reports'}>
            See All
          </NavLink>
        </div>
      </div>
      {reports.length === 0 ? (
        <div className="flex items-center gap-6 rounded-xl bg-white p-10">
          <Report className="size-16 fill-gray-300" />
          <span className="text-md font-medium">No reports.</span>
        </div>
      ) : (
        <div className="grid grid-cols-cards-smaller gap-6 lg:grid-cols-2 xl:grid-cols-3">
          {reports.map((report) => (
            <ReportCard key={report.id} report={report} />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(DashboardReports);
