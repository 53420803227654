import { memo, useMemo } from 'react';
import { ComparisonElementFormValues } from '../../dialogs/elementForms/interfaces';
import AggregationData from '../AggregationData';
import { AttributeValues, BaseReportElementProps } from '../interfaces';
import GenericMetricComparison from './GenericMetricComparison';
import { ComparisonElementPlayerResult, ComparisonElementResult, ComparisonElementTeamResult } from './interfaces';
import MultipleMetricTeamComparison from './MultipleMetricTeamComparison';
import SinglePlayerComparison from './SinglePlayerComparison';

interface ComparisonElementValues {
  result: ComparisonElementResult[];
}

const ComparisonElement = memo(function ComparisonElement({ element, report }: BaseReportElementProps) {
  const attributeValues = element.attribute_values as AttributeValues<ComparisonElementFormValues>;
  const { result: results } = element.entity_data as ComparisonElementValues;
  const reportType = report.report_type;

  return useMemo(() => {
    const isPlayer = attributeValues.attribute_type[0] === 'player';
    const isTeam = attributeValues.attribute_type[0] === 'team';
    let renderElement;
    let isSingleMetric = false;

    if (report.report_type === 'match') {
      isSingleMetric = isPlayer
        ? attributeValues.metrics_players_match.length === 1
        : attributeValues.metrics_teams_match.length === 1;
    } else {
      // Added checks for readability
      const aggregatedBy = attributeValues.aggregate_by[0] as string;
      const isSeason = aggregatedBy === 'season';
      const isMatch = aggregatedBy === 'match';
      switch (true) {
        case isPlayer && isSeason:
          isSingleMetric = attributeValues.metrics_players_season.length === 1;
          break;
        case isPlayer && isMatch:
          isSingleMetric = attributeValues.metrics_players_match.length === 1;
          break;
        case isTeam && isSeason:
          isSingleMetric = attributeValues.metrics_teams_season.length === 1;
          break;
        case isTeam && isMatch:
          isSingleMetric = attributeValues.metrics_teams_match.length === 1;
          break;
      }
    }

    if (reportType === 'league') {
      renderElement = (
        <GenericMetricComparison isPlayer={isPlayer} results={results} report={report} element={element} />
      );
    } else if (isTeam && !isSingleMetric) {
      renderElement = (
        <MultipleMetricTeamComparison
          results={results as ComparisonElementTeamResult[]}
          report={report}
          element={element}
        />
      );
    } else if (isPlayer && attributeValues?.players?.length === 1) {
      renderElement = (
        <SinglePlayerComparison
          results={results as ComparisonElementPlayerResult[]}
          report={report}
          element={element}
        />
      );
    } else {
      renderElement = (
        <GenericMetricComparison isPlayer={isPlayer} results={results} report={report} element={element} />
      );
    }

    return (
      <>
        {report.report_type !== 'match' && <AggregationData report={report} element={element} />}
        {renderElement}
      </>
    );
  }, [attributeValues, report, results, element]);
});

export default ComparisonElement;
