import Remove from 'assets/close-circle.svg?react';
import { PlayerQuerySchema } from 'lib/model';
import Jersey from 'modules/reports/reportPage/components/Jersey';
import { memo, useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { getDefaultColor } from 'modules/reports/colors';
import { ScoutReportFormValues } from './interfaces';

interface ScoutReportManualPlayerListProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
}

function ScoutReportManualPlayerList({ scoutReportForm }: ScoutReportManualPlayerListProps) {
  const players = useWatch({
    control: scoutReportForm.control,
    name: 'players'
  });

  const handleRemoveManualPlayer = useCallback(
    function handleRemoveManualPlayer(removedPlayer: PlayerQuerySchema) {
      return () => {
        const playersOld = scoutReportForm.getValues('players');
        const playersNew = playersOld.filter((player) => player.player_id !== removedPlayer.player_id);
        scoutReportForm.setValue('players', playersNew);
      };
    },
    [scoutReportForm]
  );

  if (!players || players.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      {players.map((player, i) => (
        <div key={player.player_id} className="flex items-center justify-between gap-6">
          <div className="flex w-full flex-row items-center gap-2 rounded-md border border-gray-300 p-3">
            <Jersey size="md" shirt_number={player.shirt_number} color={getDefaultColor(i)} />
            <span className="text-md font-medium">{player.player_name}</span>
          </div>
          <button className="flex items-center gap-2" onClick={handleRemoveManualPlayer(player)}>
            <span className="text-xs font-medium text-brand-800">Remove</span>
            <Remove className="size-4 fill-brand-800" />
          </button>
        </div>
      ))}
    </div>
  );
}

export default memo(ScoutReportManualPlayerList);
