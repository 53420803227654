import { getReportElementColor } from 'modules/reports/colors';
import { memo } from 'react';
import { MatchGeneralData } from '../../../reportCards/interfaces';
import ElementRow from '../../ElementRow';
import { BaseReportElementProps } from '../../interfaces';
import { LineupPlayer } from '../interfaces';
import LineupTableRow from './LineupTableRow';

interface LineupTeam {
  players: LineupPlayer[];
  team_id: number;
  team_name: string;
  team_color?: string;
  formation?: string;
}

const LineupTableElement = memo(function LineupTableElement({ element, report }: BaseReportElementProps) {
  if (!element.entity_data) return null;

  const homeTeam = element.entity_data.home_team as LineupTeam;
  const awayTeam = element.entity_data.away_team as LineupTeam;
  const matchGeneralData = report.general_data as MatchGeneralData;

  return (
    <div className="grid grid-cols-2 gap-6 max-sm:grid-cols-1">
      <div className="flex flex-col gap-6">
        <span className="text-xs font-semibold">{homeTeam.team_name}</span>
        <ElementRow>
          <span>Manager</span>
          <span>{matchGeneralData.home_team_manager_name ? `${matchGeneralData.home_team_manager_name}` : '-'}</span>
        </ElementRow>
        <div className="flex flex-col gap-2">
          {homeTeam.players.map((player) => (
            <LineupTableRow
              key={player.player_id}
              {...player}
              team_color={getReportElementColor(report, element, {
                teamId: homeTeam.team_id,
                homeOrAway: 'home'
              })}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <span className="text-xs font-semibold">{awayTeam.team_name}</span>
        <ElementRow>
          <span>Manager</span>
          <span>{matchGeneralData.away_team_manager_name ? `${matchGeneralData.away_team_manager_name}` : '-'}</span>
        </ElementRow>
        <div className="flex flex-col gap-2">
          {awayTeam.players.map((player) => (
            <LineupTableRow
              key={player.player_id}
              {...player}
              team_color={getReportElementColor(report, element, {
                teamId: awayTeam.team_id,
                homeOrAway: 'away'
              })}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default LineupTableElement;
