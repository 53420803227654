import { useQueryClient } from '@tanstack/react-query';
import Remove from 'assets/close-circle.svg?react';
import Edit from 'assets/edit.svg?react';
import { AxiosError } from 'axios';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { ReportElementTemplatesSchema, ReportNestedSchema } from 'lib/model';
import { useDeleteReportElement, useGetReportElement } from 'lib/report/report';
import Divider from 'modules/common/Divider';
import { canUserEditReport } from 'modules/reports/helpers';
import { memo, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { twJoin } from 'tailwind-merge';
import EditElementDialog from '../../dialogs/EditElementDialog';
import ComparisonElement from '../Comparison/ComparisonElement';
import EventMapElement from '../EventMap/EventMapElement';
import HeatMapElement from '../HeatMap/HeatMapElement';
import LineChartElement from '../LineChart/LineChartElement';
import LineupPitchElement from '../Lineup/LineupPitch/LineupPitchElement';
import LineupTableElement from '../Lineup/LineupTable/LineupTableElement';
import PositionMapElement from '../PositionMap/PositionMapElement';
import RadarChartElement from '../RadarChart/RadarChartElement';
import ScatterChartElement from '../ScatterChart/ScatterChartElement';
import StandingsElement from '../Standings/StandingsElement';
import TableElement from '../Table/TableElement';
import VersusElement from '../Versus/VersusElement';
import ElementCardPositionButtons from './ElementCardPositionButtons';
import ElementCardSkeleton from './ElementCardSkeleton';
import ElementCardWarning from './ElementCardWarning';
import ElementNote from './ElementNote';

interface ElementCardContentProps {
  report: ReportNestedSchema;
  elementTemplates?: ReportElementTemplatesSchema;
  elementId: string;
  first: boolean;
  last: boolean;
}

const ElementCardContent = memo(function ElementCardContent({
  elementId,
  report,
  elementTemplates,
  first,
  last
}: ElementCardContentProps) {
  const { isOwner, isAdmin, user } = useAuth();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const {
    data: element,
    isPending: isElementPending,
    isFetching: isElementFetching
  } = useGetReportElement(project.id!, report.id!, elementId, {
    query: {
      queryKey: ['project', project.id, 'reports', report.id, 'elements', elementId],
      staleTime: 1000 * 60 * 15 // 15 minutes
    }
  });
  const { mutate: deleteReportElement } = useDeleteReportElement({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        await queryClient.cancelQueries({ queryKey: ['project', project.id, 'reports', report.id!] });
        const previous = queryClient.getQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!]);
        queryClient.setQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!], (old) => {
          if (!old) return previous;
          return {
            ...old,
            report_elements: old.report_elements!.filter((el) => el !== body.elementId)
          };
        });
        return { previous, loadingToastId };
      },
      onError: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
        queryClient.setQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!], context?.previous);
      },
      onSuccess: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['project', project.id, 'reports', report.id!]
        });
        queryClient.removeQueries({
          exact: true,
          queryKey: ['project', project.id, 'reports', report.id, 'elements', elementId]
        });
      }
    }
  });

  const handleEdit = useCallback(function handleEdit() {
    setShowEdit(true);
  }, []);

  const handleRemove = useCallback(
    function handleRemove() {
      deleteReportElement({
        projectId: project.id!,
        reportId: element!.report!,
        elementId: element!.id!
      });
    },
    [deleteReportElement, element]
  );

  const ReportElement = useMemo(() => {
    if (isElementPending || !element || isElementFetching) {
      return <ElementCardSkeleton />;
    } else if (element.entity_data && 'error' in element.entity_data) {
      return <ElementCardWarning error={element.entity_data.error as string} onRemove={handleRemove} />;
    }

    switch (element.report_element_template_name) {
      case 'lineups-table':
        return <LineupTableElement report={report} element={element} />;
      case 'lineups-pitch':
        return <LineupPitchElement report={report} element={element} />;
      case 'standings':
        return <StandingsElement report={report} element={element} />;
      case 'line-chart':
        return <LineChartElement report={report} element={element} />;
      case 'versus':
        return <VersusElement report={report} element={element} />;
      case 'radar-chart':
        return <RadarChartElement report={report} element={element} />;
      case 'table':
        return <TableElement report={report} element={element} />;
      case 'comparison':
        return <ComparisonElement report={report} element={element} />;
      case 'scatter-chart':
        return <ScatterChartElement report={report} element={element} />;
      case 'heatmap-tracking-data':
      case 'heatmap-event-data':
        return <HeatMapElement report={report} element={element} />;
      case 'avg-positions-tracking-data':
      case 'avg-positions-event-data':
        return <PositionMapElement report={report} element={element} />;
      case 'event-map':
      case 'event-animation-tracking-data':
        return <EventMapElement report={report} element={element} />;
      default:
        return <ElementCardSkeleton />;
    }
  }, [element, handleRemove, isElementPending, isElementFetching, report]);

  const canEdit = isOwner || isAdmin || canUserEditReport(report!, user) || report?.owner_user === user.id;

  const elementName = useMemo(() => {
    if (elementTemplates && element) {
      return (
        elementTemplates.objects!.find((et) => et.name === element.report_element_template_name)?.display_name ??
        'Unknown'
      );
    } else {
      return 'Loading...';
    }
  }, [elementTemplates, element]);

  return (
    <>
      <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
        <header className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-3">
              {canEdit && element && (
                <ElementCardPositionButtons
                  element={element}
                  report={report}
                  isElementFetching={isElementFetching}
                  first={first}
                  last={last}
                />
              )}
              <span className="text-sm font-medium">{elementName}</span>
            </div>
            {canEdit && (
              <div className="flex items-center gap-4">
                {element?.attribute_values && Object.keys(element.attribute_values!).length > 0 && (
                  <button
                    className="group flex items-center gap-2 disabled:cursor-not-allowed"
                    onClick={handleEdit}
                    disabled={!elementTemplates || isElementFetching}
                  >
                    <span className="text-xs font-medium text-brand-800 group-disabled:text-brand-500">Edit</span>
                    <Edit className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
                  </button>
                )}
                {element && (
                  <button
                    className="group flex items-center gap-2 disabled:cursor-not-allowed"
                    onClick={handleRemove}
                    disabled={isElementFetching}
                  >
                    <span className={twJoin('text-xs font-medium text-brand-800 group-disabled:text-brand-500')}>
                      Remove
                    </span>
                    <Remove className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
                  </button>
                )}
              </div>
            )}
          </div>
          <Divider />
        </header>
        {ReportElement}
        {element && <ElementNote report={report} element={element} canEdit={canEdit} />}
      </div>
      {elementTemplates && element && showEdit && (
        <EditElementDialog
          open={showEdit}
          setOpen={setShowEdit}
          report={report}
          elementTemplates={elementTemplates.objects!}
          element={element}
        />
      )}
    </>
  );
});

export default ElementCardContent;
