import { getReportElementColor } from 'modules/reports/colors';
import { memo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { BaseReportElementProps } from '../interfaces';
import { ComparisonElementPlayerResult } from './interfaces';
import PerformanceBar from './PerformanceBar';

interface SinglePlayerComparisonProps extends BaseReportElementProps {
  results: ComparisonElementPlayerResult[];
}

const SinglePlayerComparison = memo(function SinglePlayerComparison({
  results,
  report,
  element
}: SinglePlayerComparisonProps) {
  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-cards overflow-x-auto">
        <table className="w-full table-fixed border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th className="w-1/3 text-start">
                <span className="text-xs font-semibold">{results[0].player_name}</span>
              </th>
              <th className="w-2/3" />
            </tr>
          </thead>
          <tbody className="gap-2 bg-transparent">
            {results.map((result) => (
              <tr key={result.type} className="h-12">
                <td className="truncate bg-gray-50 p-3" title={snakeCaseToWords(result.type)}>
                  <span className="text-xs font-medium">{snakeCaseToWords(result.type)}</span>
                </td>
                <td className="rounded-r-md bg-gray-50" title={String(result.value)}>
                  <PerformanceBar
                    value={result.value}
                    maxValue={result.value * 1.2}
                    color={getReportElementColor(report, element, {
                      teamId: result.team_id,
                      playerId: result.player_id
                    })}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default SinglePlayerComparison;
