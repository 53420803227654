import useActiveProject from 'contexts/project/projectContext';
import { MatchSchema, ScoutNoteCreateSchemaType } from 'lib/model';
import { useGetProjectScoutMetrics } from 'lib/scout-metric/scout-metric';
import { useGetPlayerMatches, useGetPlayersInfinite } from 'lib/search/search';
import Button from 'modules/common/Button';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import InputBase from 'modules/common/Form/Input/InputBase';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { convertStringToInputDate } from 'utils/helpers';
import { playerToAutocompleteOption, scoutReferenceToValueOption, stringToValueOption } from 'utils/mappings';
import { DEFAULT_NUMBER_OF_SCOUT_METRICS, SCOUT_NOTE_TYPES } from '../constants';
import { sortScoutMetrics } from '../helpers';
import { ScoutNoteFormValues } from '../interfaces';
import ScoutFormRating, { ScoutFormRatingSkeleton } from './components/ScoutFormRating';

interface Props {
  defaultValues?: Partial<ScoutNoteFormValues>;
  onSubmit: (data: ScoutNoteFormValues) => void;
  confirmText?: string;
}

const ScoutNoteForm = ({ defaultValues, onSubmit, confirmText = 'Create Scout Note' }: Props) => {
  const navigate = useNavigate();
  const { noteId } = useParams();
  const { project } = useActiveProject();

  const { data: scoutMetricsData, isFetching: isFetchingMetrics } = useGetProjectScoutMetrics(project.id!, {
    query: {
      queryKey: ['project', project.id, 'scout-metrics'],
      staleTime: Infinity
    }
  });

  const scoutMetrics = useMemo(() => {
    const items = scoutMetricsData?.objects ?? [];
    return items.sort(sortScoutMetrics);
  }, [scoutMetricsData]);

  const overallMetric = scoutMetrics.find((metric) => metric.name === 'Overall');

  // Form setup
  const {
    handleSubmit: formSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues
  } = useForm<ScoutNoteFormValues>({
    defaultValues
  });
  const player = useWatch({ control, name: 'player' });
  const reference = useWatch({ control, name: 'reference' });
  const noteDate = useWatch({ control, name: 'note_date' });
  const noteType = useWatch({ control, name: 'type' });

  // Update note date when match selected
  useEffect(() => {
    if (reference) {
      const noteDate = (reference.value as MatchSchema).date;
      if (noteDate) {
        const formattedDate = convertStringToInputDate(noteDate);
        if (formattedDate) {
          setValue('note_date', formattedDate);
        }
      }
    }
  }, [reference]);

  // Actions
  function handleCancel() {
    if (noteId) {
      navigate(`/scout-notes/${noteId}`);
    } else {
      navigate(`/scout-notes`);
    }
  }

  // Infinite queries
  const [playerQuery, setPlayerQuery] = useState<string>('');
  const {
    data: playersData,
    fetchNextPage: fetchNextPlayers,
    isFetchingNextPage: isFetchingNextPlayers,
    hasNextPage: hasNextPlayers,
    status: playersStatus
  } = useGetPlayersInfinite(
    project.id!,
    {
      player_name: playerQuery
    },
    {
      query: {
        queryKey: ['players', project.id, playerQuery],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const infinitePlayersOptions = useMemo(() => {
    const allRows = playersData ? playersData.pages.flatMap((d) => d.players) : [];
    return allRows.map(playerToAutocompleteOption) ?? [];
  }, [playersData]);

  const { data: matchesData, isFetching: isFetchingMatches } = useGetPlayerMatches(
    project.id!,
    { player_id: player?.id as string | undefined },
    {
      query: {
        queryKey: ['project', project.id, 'player', player?.id, 'matches'],
        staleTime: Infinity,
        enabled: !!project.id && !!player?.id
      }
    }
  );

  const matchOptions = useMemo(() => {
    return matchesData?.objects?.map(scoutReferenceToValueOption) ?? [];
  }, [matchesData]);

  // Update selected match when player changed
  useEffect(() => {
    const reference = getValues('reference');
    const defaultReference = defaultValues?.reference;
    if (player) {
      // Update selected match
      const selectedMatch = reference ?? defaultReference;
      if (matchOptions.length > 0 && selectedMatch) {
        const matchFound = matchOptions.find((match) => match.id === selectedMatch.id);
        if (!matchFound) {
          setValue('reference', null);
        }
      }
    }
  }, [player, matchOptions, setValue, getValues]);

  // Update note name
  useEffect(() => {
    if (player && noteDate) {
      const date = new Date(noteDate);
      const noteName = `${player.label} - ${date.toLocaleDateString()}`;
      setValue('note_name', noteName);
    }
  }, [player, noteDate, setValue]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-scout-note-form" onSubmit={formSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <span className="text-md font-semibold">Find Player</span>
        <SelectInput
          loading={playersStatus === 'pending'}
          label={'Player'}
          placeholder="Start typing: Player name / Team / Player country"
          options={infinitePlayersOptions}
          onInputChange={setPlayerQuery}
          searchable
          infiniteQuery={{
            hasNextPage: hasNextPlayers,
            fetchNextPage: fetchNextPlayers,
            isFetchingNextPage: isFetchingNextPlayers
          }}
          error={errors.player}
          formProps={{
            name: 'player',
            rules: { required: { value: true, message: 'Player is required.' } },
            control: control
          }}
        />
      </div>
      <div className="flex flex-col gap-6">
        <span className="text-md font-semibold">Category</span>
        <div className={twJoin('flex gap-4 max-sm:flex-col')}>
          <SelectInput
            label={'Type'}
            error={errors.type}
            placeholder="Category"
            options={SCOUT_NOTE_TYPES.map(stringToValueOption)}
            formProps={{
              name: 'type',
              rules: { required: { value: true, message: 'Category is required.' } },
              control: control
            }}
          />
          <div className="relative flex w-full sm:max-w-[200px]">
            <Input
              label="Date"
              fullWidth
              registerReturn={register('note_date', { required: 'Date is required' })}
              type="date"
              error={errors.note_date}
            />
          </div>
        </div>
        {noteType && noteType.id === ScoutNoteCreateSchemaType.match && (
          <SelectInput
            disabled={!player || matchOptions.length === 0}
            loading={isFetchingMatches}
            label={'Match'}
            placeholder={
              !isFetchingMatches && matchOptions.length === 0 ? 'No available matches' : 'Start typing: Match name'
            }
            options={matchOptions}
            searchable
            error={errors.reference}
            formProps={{
              name: 'reference',
              control: control
            }}
          />
        )}
      </div>
      <div className="flex flex-col gap-6">
        <div className="text-md font-semibold">Note Name</div>
        <Input
          label="Enter note name"
          registerReturn={register('note_name', { required: 'Note name is required' })}
          error={errors.note_name}
        />
      </div>
      <div className="flex flex-col gap-6">
        <h3 className="text-lg font-semibold">Ratings</h3>
        {isFetchingMetrics ? (
          <>
            {Array.from({ length: DEFAULT_NUMBER_OF_SCOUT_METRICS }).map((_, index) => (
              <ScoutFormRatingSkeleton key={index} />
            ))}
          </>
        ) : (
          scoutMetrics.map((metric, index) => (
            <ScoutFormRating
              key={metric.id}
              control={control}
              index={index}
              metric={metric}
              setValue={setValue}
              getValues={getValues}
              overallMetric={overallMetric}
            />
          ))
        )}
      </div>
      <div className="flex flex-col gap-6">
        <div className="text-md font-semibold">Estimated value</div>
        <Controller
          render={(props) => (
            <>
              <NumericFormat
                customInput={InputBase}
                label="Estimated value"
                thousandSeparator={true}
                prefix="€ "
                onValueChange={(values) => {
                  props.field.onChange(values.value);
                }}
                value={props.field.value}
                error={props.formState.errors.estimated_value}
              />
              {props.formState.errors.estimated_value && (
                <ErrorMessage error={props.formState.errors.estimated_value} />
              )}
            </>
          )}
          name="estimated_value"
          control={control}
          rules={{ max: { value: 1000000000, message: `Player value can't be over 1 billion €` } }}
        />
      </div>
      {errors?.root && <ErrorMessage error={errors.root} />}
      <div className="flex gap-6">
        <Button variant="secondary" size="xl" onClick={handleCancel} disabled={isSubmitting}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-scout-note-form" disabled={isSubmitting}>
          <span>{confirmText}</span>
        </Button>
      </div>
    </form>
  );
};

export default ScoutNoteForm;
