/* eslint-disable @typescript-eslint/no-explicit-any */
import Previous from 'assets/chevron-left.svg?react';
import Next from 'assets/chevron-right.svg?react';
import { defaultPageSize } from 'constants/tableDefaults';
import { memo, PropsWithChildren, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface PaginationProps {
  rowCount?: number;
  disabled?: boolean;
}

interface ArrowButtonsProps {
  disabled?: boolean;
  onClick: () => void;
}
function ArrowButton({ disabled, onClick, children }: PropsWithChildren<ArrowButtonsProps>) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="flex size-10 items-center justify-center rounded-md bg-brand-800 disabled:bg-brand-950"
    >
      {children}
    </button>
  );
}

interface PageButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  text: number | string;
}
function PageButton({ disabled, onClick, isActive, text }: PageButtonProps) {
  const Element = onClick === undefined ? 'span' : 'button';
  return (
    <Element
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        'flex h-10 w-10 items-center justify-center rounded-md',
        isActive ? 'border border-brand-800 bg-brand-50' : 'bg-white'
      )}
    >
      <span
        className={twMerge(
          'text-xs font-semibold',
          isActive ? 'text-brand-800' : disabled ? 'text-gray-400' : 'text-gray-600'
        )}
      >
        {text}
      </span>
    </Element>
  );
}

const Pagination = memo(function Pagination({ rowCount, disabled }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePrevious = useCallback(
    function handlePrevious() {
      setSearchParams((old) => {
        const newPage = String(Number(searchParams.get('pageIndex')!) - 1);
        old.set('pageIndex', newPage);
        return old;
      });
    },
    [searchParams, setSearchParams]
  );

  const handleNext = useCallback(
    function handleNext() {
      setSearchParams((old) => {
        const newPage = String(Number(searchParams.get('pageIndex')!) + 1);
        old.set('pageIndex', newPage);
        return old;
      });
    },
    [searchParams, setSearchParams]
  );

  const handleLast = useCallback(
    function handleLast() {
      setSearchParams((old) => {
        const newPage = String(
          Math.max(Math.floor((rowCount! - 1) / Number(searchParams.get('pageSize') ?? defaultPageSize)), 0)
        );
        old.set('pageIndex', newPage);
        return old;
      });
    },
    [rowCount, searchParams, setSearchParams]
  );

  const handleFirst = useCallback(
    function handleFirst() {
      setSearchParams((old) => {
        old.set('pageIndex', '0');
        return old;
      });
    },
    [setSearchParams]
  );

  const handleNavigate = useCallback(
    function handleNavigate(page: number) {
      return () => {
        setSearchParams((old) => {
          old.set('pageIndex', String(page));
          return old;
        });
      };
    },
    [setSearchParams]
  );

  if (rowCount === 0) {
    return null;
  }

  if (!rowCount) {
    return <PaginationSkeleton />;
  }

  const lastPage = Math.max(Math.floor((rowCount! - 1) / Number(searchParams.get('pageSize') ?? defaultPageSize)), 0);
  const currentPage = Number(searchParams.get('pageIndex')!);

  const canGetPrevious = currentPage > 0;
  const canGetNext = currentPage < lastPage;

  // the goal is to have 9 buttons on screen at all times if possible
  // spread 4 from current
  let start = Math.max(0, currentPage - 4);
  let end = Math.min(lastPage, currentPage + 4);

  // if near first page or last page, transfer reminder to other side
  const startReminder = 4 + start - currentPage;
  const endReminder = 4 + currentPage - end;

  // add reminder, but don't go out of borders
  start = Math.max(0, start - endReminder);
  end = Math.min(lastPage, end + startReminder);

  // if too far from border, show border button
  const showFirst = currentPage - 4 > 0;
  const showLast = currentPage + 4 < lastPage;

  // if showing border button, reduce that side by 2
  if (showFirst) {
    start += 2;
  }
  if (showLast) {
    end -= 2;
  }

  const pages = Array.from({ length: end - start + 1 }, (_, i) => i + start);

  return (
    <div className="flex items-center justify-center gap-2 p-6">
      {pages.length > 1 && (
        <ArrowButton disabled={!canGetPrevious || disabled} onClick={handlePrevious}>
          <Previous width={24} height={24} className="fill-white" />
        </ArrowButton>
      )}
      {showFirst && (
        <>
          <PageButton onClick={handleFirst} disabled={disabled} text={1} />
          <PageButton disabled={disabled} text={'...'} />
        </>
      )}
      {pages.map((page) => (
        <PageButton
          key={page}
          disabled={disabled}
          onClick={handleNavigate(page)}
          isActive={currentPage === page}
          text={page + 1}
        />
      ))}
      {showLast && (
        <>
          <PageButton disabled={disabled} text={'...'} />
          <PageButton onClick={handleLast} disabled={disabled} text={lastPage + 1} />
        </>
      )}
      {pages.length > 1 && (
        <ArrowButton disabled={!canGetNext || disabled} onClick={handleNext}>
          <Next width={24} height={24} className="fill-white" />
        </ArrowButton>
      )}
    </div>
  );
});

export default Pagination;

const PaginationSkeleton = memo(function PaginationSkeleton() {
  return (
    <div className="flex items-center justify-center gap-2 p-6">
      <div className="size-10 animate-pulse rounded-md bg-gray-100" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-100" />
    </div>
  );
});
