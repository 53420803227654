import { getReportElementColor } from 'modules/reports/colors';
import { memo, useMemo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { MatchGeneralData, TeamComparisonGeneralData } from '../../reportCards/interfaces';
import { BaseReportElementProps } from '../interfaces';
import { ComparisonElementTeamResult } from './interfaces';
import PerformanceBar from './PerformanceBar';

interface MultipleMetricTeamComparisonProps extends BaseReportElementProps {
  results: ComparisonElementTeamResult[];
}

interface TeamComparisonRow {
  homeTeamValue?: number;
  awayTeamValue?: number;
  maxValue: number;
  type: string;
}

interface TeamComparisonData {
  homeTeamId: number;
  homeTeamName: string;
  homeTeamColor?: string;
  awayTeamId: number;
  awayTeamName: string;
  awayTeamColor?: string;
  results: TeamComparisonRow[];
}

const MultipleMetricTeamComparison = memo(function MultipleMetricTeamComparison({
  results,
  report,
  element
}: MultipleMetricTeamComparisonProps) {
  const data = useMemo(() => {
    let dataObj: TeamComparisonData;
    if (report.report_type === 'match') {
      const generalData = report.general_data as MatchGeneralData;
      dataObj = {
        homeTeamId: generalData.home_team_id,
        awayTeamId: generalData.away_team_id,
        homeTeamName: generalData.home_team_name,
        awayTeamName: generalData.away_team_name,
        homeTeamColor: generalData.home_team_color,
        awayTeamColor: generalData.away_team_color,
        results: []
      };
    } else if (report.report_type === 'team_comparison') {
      const generalData = report.general_data as TeamComparisonGeneralData;
      dataObj = {
        homeTeamId: generalData.team1.team_id,
        awayTeamId: generalData.team2.team_id,
        homeTeamName: generalData.team1.team_name,
        awayTeamName: generalData.team2.team_name,
        homeTeamColor: generalData.team1.color,
        awayTeamColor: generalData.team2.color,
        results: []
      };
    }
    const resultsObj = results.reduce((acc: Record<string, TeamComparisonRow>, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = {
          type: curr.type,
          maxValue: 0
        };
      }
      if (report.report_type === 'match') {
        const generalData = report.general_data as MatchGeneralData;
        if (generalData.home_team_id === curr.team_id) {
          acc[curr.type].homeTeamValue = curr.value;
        } else {
          acc[curr.type].awayTeamValue = curr.value;
        }
      } else if (report.report_type === 'team_comparison') {
        const generalData = report.general_data as TeamComparisonGeneralData;
        if (generalData.team1.team_id === curr.team_id) {
          acc[curr.type].homeTeamValue = curr.value;
        } else {
          acc[curr.type].awayTeamValue = curr.value;
        }
      }
      if (curr.value * 1.2 > acc[curr.type].maxValue) {
        acc[curr.type].maxValue = curr.value * 1.2;
      }
      return acc as Record<string, TeamComparisonRow>;
    }, {});

    dataObj!.results.push(...Object.values(resultsObj));

    return dataObj!;
  }, [report.general_data, report.report_type, results]);

  return (
    <div className="grid grid-cols-cards overflow-x-auto">
      <table className="w-full table-fixed border-separate border-spacing-y-2">
        <thead>
          <tr>
            <th className="text-start">
              <span className="text-xs font-semibold">{data.homeTeamName}</span>
            </th>
            <th />
            <th className="text-end">
              <span className="text-xs font-semibold">{data.awayTeamName}</span>
            </th>
          </tr>
        </thead>
        <tbody className="gap-2 bg-transparent">
          {data.results.map((row) => (
            <tr key={row.type} className="h-12">
              <td className="rounded-l-md bg-gray-50" title={String(row.homeTeamValue)}>
                <PerformanceBar
                  value={row.homeTeamValue!}
                  maxValue={row.maxValue}
                  color={getReportElementColor(report, element, { teamId: data.homeTeamId, homeOrAway: 'home' })}
                />
              </td>
              <td className="whitespace-nowrap bg-gray-50 text-center">
                <span className="px-3 text-xs font-medium">{snakeCaseToWords(row.type)}</span>
              </td>
              <td className="rounded-r-md bg-gray-50" title={String(row.awayTeamValue)}>
                <PerformanceBar
                  value={row.awayTeamValue!}
                  maxValue={row.maxValue}
                  color={getReportElementColor(report, element, { teamId: data.awayTeamId, homeOrAway: 'away' })}
                  reverse
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default MultipleMetricTeamComparison;
