import { defaultDataTablePageSize } from 'constants/tableDefaults';
import Pagination from 'modules/common/Pagination';
import { useSearchParams } from 'react-router-dom';

interface PaginationWithCountProps {
  rowCount?: number;
  disabled?: boolean;
}

function PaginationWithCount({ rowCount, disabled }: PaginationWithCountProps) {
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('pageIndex') ?? 0);
  const currentPageSize = Number(searchParams.get('pageSize') ?? defaultDataTablePageSize);

  const currentPageStart = currentPage * currentPageSize + 1;
  let currentPageEnd = (currentPage + 1) * currentPageSize;
  if (rowCount) {
    currentPageEnd = Math.min(currentPageEnd, rowCount);
  }

  return (
    <div className="relative mt-auto flex items-center justify-center py-6">
      <Pagination rowCount={rowCount} disabled={disabled} />
      {!!rowCount && rowCount > 0 && (
        <div className="absolute right-[10%] flex gap-2">
          <span className="text-xs font-semibold">{`${currentPageStart}-${currentPageEnd}`}</span>
          <span className="text-xs text-gray-300">/</span>
          <span className="text-xs font-semibold">{rowCount ?? '-'}</span>
        </div>
      )}
    </div>
  );
}

export default PaginationWithCount;
