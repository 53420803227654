import { getReportElementColor } from 'modules/reports/colors';
import { memo, useMemo } from 'react';
import { BaseReportElementProps } from '../../../interfaces';
import { LineupTeam } from '../../interfaces';
import LineupPitchFormation from '../LineupPitchFormation';
import LineupPitchGrid from '../LineupPitchGrid';
import { createTeamGrid } from './helpers';

const TeamComparisonLineupPitch = memo(function TeamComparisonLineupPitch({ report, element }: BaseReportElementProps) {
  const team1 = element.entity_data!.team1.team as LineupTeam;
  team1.team_color ??= getReportElementColor(report, element, { teamId: team1.team_id });
  const team2 = element.entity_data!.team2.team as LineupTeam;
  team2.team_color ??= getReportElementColor(report, element, { teamId: team2.team_id });

  const team1Grid = useMemo(() => createTeamGrid(team1), [team1]);
  const team2Grid = useMemo(() => createTeamGrid(team2), [team2]);

  return (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold">Most commonly used formation from the last 10 games</span>
      <div className="flex flex-col gap-6 rounded-xl bg-gray-50">
        <div className="grid w-full grid-cols-[max-content_minmax(100px,640px)] justify-center gap-x-0 gap-y-4">
          <div className="px-6 py-4">
            <LineupPitchFormation team={team1} />
          </div>
          <div
            className="relative grid aspect-football-field-half w-full max-w-screen-sm grid-cols-1 bg-football-field-upper-half bg-contain p-football-field"
            style={{ gridTemplateRows: `repeat(${team1Grid?.length ?? 0}, minmax(0, 1fr))` }}
          >
            <LineupPitchGrid report={report} element={element} grid={team1Grid} team={team1} widthBorder={400} />
          </div>

          <div className="px-6 py-4">
            <LineupPitchFormation team={team2} />
          </div>
          <div
            className="relative grid aspect-football-field-half w-full max-w-screen-sm grid-cols-1 bg-football-field-lower-half bg-contain p-football-field"
            style={{ gridTemplateRows: `repeat(${team2Grid?.length ?? 0}, minmax(0, 1fr))` }}
          >
            <LineupPitchGrid
              report={report}
              element={element}
              grid={team2Grid}
              team={team2}
              widthBorder={400}
              reversed
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default TeamComparisonLineupPitch;
