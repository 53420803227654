import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useCreateReport } from 'lib/report/report';
import { useGetPlayersInfinite } from 'lib/search/search';

import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';
import { playerToAutocompleteOption } from 'utils/mappings';

interface NewPlayerComparisonReportFormValues {
  name: string;
  player1: AutocompleteOption | null;
  player2: AutocompleteOption | null;
}

const NewPlayerComparisonReportDialog = memo(function NewPlayerComparisonReportDialog({ open, setOpen }: DialogProps) {
  const [player1Query, setPlayer1Query] = useState<string>('');
  const [player2Query, setPlayer2Query] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    setError,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<NewPlayerComparisonReportFormValues>({
    defaultValues: {
      name: '',
      player1: null,
      player2: null
    }
  });

  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const {
    data: players1,
    fetchNextPage: fetchNextPlayers1,
    isFetchingNextPage: isFetchingNextPlayers1,
    hasNextPage: hasNextPlayers1,
    status: statusPlayers1
  } = useGetPlayersInfinite(
    project.id!,
    {
      player_name: player1Query
    },
    {
      query: {
        queryKey: ['players', project.id, player1Query],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const players1Options = useMemo(() => {
    const allRows = players1 ? players1.pages.flatMap((d) => d.players) : [];
    return allRows.map(playerToAutocompleteOption) ?? [];
  }, [players1]);

  const {
    data: players2,
    fetchNextPage: fetchNextPlayers2,
    isFetchingNextPage: isFetchingNextPlayers2,
    hasNextPage: hasNextPlayers2,
    status: statusPlayers2
  } = useGetPlayersInfinite(
    project.id!,
    {
      player_name: player2Query
    },
    {
      query: {
        queryKey: ['players', project.id, player2Query],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const players2Options = useMemo(() => {
    const allRows = players2 ? players2.pages.flatMap((d) => d.players) : [];
    return allRows.map(playerToAutocompleteOption) ?? [];
  }, [players2]);

  const { mutate: createReport, isPending: isCreating } = useCreateReport();

  function handleSubmit(data: NewPlayerComparisonReportFormValues) {
    if (!data.player1 || !data.player2) return;
    if (data.player1.id === data.player2.id) {
      setError('root', { message: "Can't compare between identical players", type: 'validate' });
      return;
    }
    createReport(
      {
        projectId: project!.id!,
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.player_comparison,
          project: project!.id!,
          report_entities: [data.player1.id as number, data.player2.id as number] // TODO: figure out the format
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['project', project.id!, 'reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedPlayer1 = watch('player1');
  const selectedPlayer2 = watch('player2');
  useEffect(() => {
    if (selectedPlayer1 && selectedPlayer2) {
      setValue('name', `${selectedPlayer1.label} - ${selectedPlayer2.label}`);
    } else {
      setValue('name', '');
    }
  }, [selectedPlayer1, selectedPlayer2, setValue]);

  return (
    <DialogBase title="New Player Comparison Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form
          className="flex w-full flex-col gap-10"
          id="new-player-comparison-report-form"
          onSubmit={formSubmit(handleSubmit)}
        >
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Players</span>
            <SelectInput
              loading={statusPlayers1 === 'pending'}
              label={'Player 1'}
              placeholder="Start typing: Player name / Team / Player country"
              options={players1Options}
              error={errors.player1}
              onInputChange={setPlayer1Query}
              searchable
              infiniteQuery={{
                hasNextPage: hasNextPlayers1,
                fetchNextPage: fetchNextPlayers1,
                isFetchingNextPage: isFetchingNextPlayers1
              }}
              formProps={{
                name: 'player1',
                rules: { required: { value: true, message: 'Player is required.' } },
                control: control
              }}
            />

            <SelectInput
              loading={statusPlayers2 === 'pending'}
              label={'Player 2'}
              placeholder="Start typing: Player name / Team / Player country"
              options={players2Options}
              error={errors.player2}
              onInputChange={setPlayer2Query}
              searchable
              infiniteQuery={{
                hasNextPage: hasNextPlayers2,
                fetchNextPage: fetchNextPlayers2,
                isFetchingNextPage: isFetchingNextPlayers2
              }}
              formProps={{
                name: 'player2',
                rules: { required: { value: true, message: 'Player is required.' } },
                control: control
              }}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-player-comparison-report-form" loading={isCreating}>
          <span>Create Player Comparison Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewPlayerComparisonReportDialog;
