import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  PaginationState,
  Row,
  useReactTable
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { AdminProjectSchema } from '../../interfaces';
import DashboardProjectCard from '../DashboardProjectCard';
import SubscriptionCell from './cells/SubscriptionCell';

import ChevronDown from 'assets/chevron-down.svg?react';
import ChevronUp from 'assets/chevron-up.svg?react';

import PaginationWithState from 'modules/common/PaginationWithState';
import GenericTable from 'modules/common/Table/GenericTable';
import AwsCostCell from './cells/AwsCostCell';
import { LastLoginCell } from './cells/ProjectLastLoginCell';
import ReportsCell from './cells/ReportsCell';
import StatusCell from './cells/StatusCell';
import UsersCell from './cells/UsersCell';

interface ProjectsTableProps {
  tableData: AdminProjectSchema[];
}

const renderSubComponent = ({ row }: { row: Row<AdminProjectSchema> }) => {
  return <DashboardProjectCard project={row.original} />;
};

const HeaderCell = ({ title }: { title: string }) => {
  return <div className={twJoin('mx-2 text-tiny font-medium uppercase text-gray-500 max-md:hidden')}>{title}</div>;
};

const ProjectsTable = ({ tableData }: ProjectsTableProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const columnHelper = createColumnHelper<AdminProjectSchema>();
  const columns = useMemo(() => {
    const columns: ColumnDef<AdminProjectSchema>[] = [
      columnHelper.accessor('organization_name', {
        id: 'organization',
        header: () => <HeaderCell title="ORGANIZATION" />,
        cell: (info) => {
          return (
            <div className="mx-3 ml-6 flex items-center gap-2">
              <span className={'text-wrap text-xs font-medium'}>{info.renderValue()}</span>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'users',
        header: () => <HeaderCell title="USERS" />,
        cell: ({ row }) => {
          return <UsersCell project={row.original} />;
        },
        meta: 'text-center'
      }),
      columnHelper.display({
        id: 'reports',
        header: () => <HeaderCell title="REPORTS" />,
        cell: (props) => <ReportsCell {...props} />,
        meta: 'text-center'
      }),
      columnHelper.display({
        id: 'subscription',
        header: () => <HeaderCell title="SUBSCRIPTION" />,
        cell: ({ row }) => <SubscriptionCell project={row.original} />
      }),
      columnHelper.display({
        id: 'last-login',
        header: () => <HeaderCell title="LAST LOGIN" />,
        cell: ({ row }) => <LastLoginCell project={row.original} />
      }),
      columnHelper.accessor('aws-cost', {
        header: () => <HeaderCell title="COST / MONTH" />,
        cell: (props) => <AwsCostCell {...props} />
      }),
      columnHelper.display({
        id: 'status',
        header: () => <HeaderCell title="STATUS" />,
        cell: ({ row }) => {
          return <StatusCell project={row.original} />;
        },
        meta: 'text-center'
      }),
      columnHelper.display({
        id: 'expander',
        header: () => null,
        cell: ({ row }) => {
          return (
            <div className="mx-2">
              {row.getIsExpanded() ? (
                <ChevronUp width={24} height={24} className="fill-gray-800" />
              ) : (
                <ChevronDown width={24} height={24} className="fill-gray-800" />
              )}
            </div>
          );
        }
      })
    ];
    return columns;
  }, [columnHelper]);

  const table = useReactTable({
    data: tableData ?? [],
    columns,
    state: {
      pagination,
      columnPinning: {
        right: ['expander']
      }
    },
    getRowCanExpand: ({ original }) => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <>
      <GenericTable
        table={table}
        SubComponent={renderSubComponent}
        onRowClick={(row) => {
          if (row.getCanExpand()) {
            row.toggleExpanded();
          }
        }}
      />
      <PaginationWithState pagination={pagination} setPagination={setPagination} rowCount={tableData.length} />
    </>
  );
};

export default ProjectsTable;
