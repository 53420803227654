import { useGetPublicReport } from 'lib/report/report';
import { memo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { snakeCaseToWords } from 'utils/helpers';
import ReportCard from '../reportPage/components/reportCards/ReportCard';
import PublicElementCard from './components/PublicElementCard';

const PublicReportPage = memo(function PublicReportPage() {
  const { reportId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? undefined;

  const { data: report } = useGetPublicReport(
    reportId!,
    {
      token: token!
    },
    {
      query: {
        queryKey: ['public', 'reports', reportId!, token],
        enabled: !!token,
        staleTime: Infinity,
        refetchOnWindowFocus: false
      }
    }
  );

  if (!token) {
    return null;
  }

  return (
    <div className="flex min-h-screen w-full flex-col items-center px-6 pb-6">
      <div className="relative flex w-full max-w-6xl flex-col">
        <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 pb-8 pt-10">
          <div className="flex w-full items-center gap-2">
            {report ? (
              <div className="flex flex-col">
                <div className="text-tiny font-medium text-gray-950">
                  {snakeCaseToWords(report?.report_type ?? '')} Report
                </div>
                <span className="text-lg font-semibold text-gray-950">{report?.name}</span>
              </div>
            ) : (
              <div className="flex w-full flex-col gap-1">
                <div className="h-4 w-16 animate-pulse rounded-md bg-gray-200" />
                <div className="h-6 w-1/2 animate-pulse rounded-md bg-gray-200" />
              </div>
            )}
          </div>
        </header>
        <div className={twJoin('relative flex gap-6 max-lg:flex-col', report?.report_type === 'scout' && 'flex-col')}>
          <div
            className={twJoin(
              'flex h-fit flex-col gap-6 lg:top-[114px] lg:min-w-[400px]',
              report?.report_type !== 'scout' && 'lg:sticky'
            )}
          >
            <ReportCard report={report} />
          </div>
          <div className="flex w-full flex-col gap-6">
            {report?.report_elements?.map((elementId) => (
              <PublicElementCard key={elementId} report={report} elementId={elementId} token={token} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PublicReportPage;
