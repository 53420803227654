import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ReportElementSchema } from 'lib/model';
import { getGetLastEditedReportsQueryKey, useEditReportElement } from 'lib/report/report';
import { getReportElementColor } from 'modules/reports/colors';
import { memo, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { MatchInfo } from 'utils/interfaces';
import ElementMatchInfo from '../ElementMatchInfo';
import { BaseReportElementProps } from '../interfaces';
import PlayerPositionChart from './PlayerPositionChart';
import MatchTimeSlider from './components/MatchTimeSlider';
import { MatchTimePoint, PositionMapPlayerData, PositionMapPoint } from './interfaces';

interface PositionMapData {
  'avg-positions-home': PositionMapPoint[];
  'avg-positions-away': PositionMapPoint[];
  match_info: MatchInfo;
}

function reduceTimePoints(times: { [key: number]: MatchTimePoint }, player: PositionMapPlayerData) {
  if (player.minute_subbed_out && Number(player.minute_subbed_out) !== 0) {
    const minuteSubbedOut = Number(player.minute_subbed_out);
    const subbedOut = times[minuteSubbedOut]?.subbed_out ?? [];
    subbedOut.push(player);
    times[minuteSubbedOut] = {
      ...times[minuteSubbedOut],
      match_time: minuteSubbedOut,
      subbed_out: subbedOut
    };
  }
  if (player.minute_subbed_in && Number(player.minute_subbed_in) !== 0) {
    const minuteSubbedIn = Number(player.minute_subbed_in);
    const subbedIn = times[minuteSubbedIn]?.subbed_in ?? [];
    subbedIn.push(player);
    times[minuteSubbedIn] = {
      ...times[minuteSubbedIn],
      match_time: minuteSubbedIn,
      subbed_in: subbedIn
    };
  }
  return times;
}

function filterCurrentPlayers(player: PositionMapPoint, match_time: number) {
  if (player.player_started) {
    // Player started the match and was not subbed out
    return (player.minute_subbed_out && player.minute_subbed_out > match_time) || !player.minute_subbed_out;
  } else if (player.minute_subbed_in && player.minute_subbed_out) {
    // Player was subbed in and out
    return player.minute_subbed_in <= match_time && player.minute_subbed_out > match_time;
  } else {
    // Player was subbed in
    return player.minute_subbed_in && player.minute_subbed_in <= match_time;
  }
}

const PositionMapElement = memo(function PositionMapElement({ element, report }: BaseReportElementProps) {
  const { project } = useActiveProject();
  const queryClient = useQueryClient();
  // If outdated element, it has no match time
  const matchTime = element.attribute_values?.match_time as number[] | undefined;
  const [currentTime, setCurrentTime] = useState<number>(matchTime ? matchTime[0] : 0);
  const data = element.entity_data as PositionMapData;
  const match = data.match_info;

  const { allHomePlayers, allAwayPlayers } = useMemo(() => {
    const allHomePlayers = (data['avg-positions-home'] ?? []).map((player) => {
      return {
        ...player,
        team_color: getReportElementColor(report, element, {
          playerId: player.player_id,
          teamId: player.team_id,
          homeOrAway: 'home'
        })
      };
    });
    const allAwayPlayers = (data['avg-positions-away'] ?? []).map((player) => {
      return {
        ...player,
        team_color: getReportElementColor(report, element, {
          playerId: player.player_id,
          teamId: player.team_id,
          homeOrAway: 'away'
        })
      };
    });

    return { allHomePlayers, allAwayPlayers };
  }, [element.entity_data]);

  const matchTimePoints = useMemo(() => {
    const times: { [key: number]: MatchTimePoint } = {};

    allHomePlayers.reduce(reduceTimePoints, times);
    allAwayPlayers.reduce(reduceTimePoints, times);

    return times;
  }, [allHomePlayers, allAwayPlayers]);

  const awayPlayers = useMemo(() => {
    return allAwayPlayers.filter((player) => filterCurrentPlayers(player, currentTime));
  }, [allAwayPlayers, currentTime]);

  const homePlayers = useMemo(() => {
    return allHomePlayers.filter((player) => filterCurrentPlayers(player, currentTime));
  }, [allHomePlayers, currentTime]);

  const { mutate: editElement } = useEditReportElement({
    mutation: {
      onMutate: async ({ data }) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        setCurrentTime(data.attribute_values?.match_time[0] ?? 0);
        return { loadingToastId };
      },
      onError: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
      },
      onSuccess: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
        queryClient.setQueryData(['project', project.id, 'reports', report.id!, 'elements', element.id], (oldData: ReportElementSchema) => {
          return {
            ...oldData,
            attribute_values: {
              ...oldData.attribute_values,
              match_time: res.data.attribute_values?.match_time
            }
          };
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: getGetLastEditedReportsQueryKey(project.id!)
        });
      }
    }
  });

  function onChangeTime(time: number) {
    editElement({
      projectId: project.id!,
      reportId: report.id!,
      elementId: element.id!,
      data: {
        report_element_template: element.report_element_template!,
        position: element.position,
        attribute_values: { ...element.attribute_values, match_time: [time] }
      }
    });
  }

  return (
    <div className="flex w-full justify-center">
      <div className="flex w-full max-w-screen-lg flex-col gap-6 self-center">
        {report.report_type !== 'match' && match && <ElementMatchInfo match={match} element={element} />}
        {Object.keys(matchTimePoints).length > 0 && (
          <MatchTimeSlider timePoints={matchTimePoints} currentTime={currentTime} onChangeTime={onChangeTime} />
        )}
        <PlayerPositionChart away={awayPlayers} home={homePlayers} match={match} report={report} element={element} />
      </div>
    </div>
  );
});

export default PositionMapElement;
