import { useQueryClient } from '@tanstack/react-query';
import useActiveProject from 'contexts/project/projectContext';
import { ReportEditSchema, ReportNestedSchema } from 'lib/model';
import { useEditReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Input from 'modules/common/Form/Input';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface RenameReportDialogFormValues {
  name: string;
}

interface RenameReportDialogProps extends DialogProps {
  report: ReportNestedSchema;
}

const RenameReportDialog = memo(function RenameReportDialog({ open, setOpen, report }: RenameReportDialogProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors }
  } = useForm<RenameReportDialogFormValues>({
    defaultValues: {
      name: report.name
    }
  });

  const { mutate: updateReport, isPending: isUpdatingReport } = useEditReport({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        await queryClient.cancelQueries({ queryKey: ['project', project.id, 'reports', report.id!] });
        const previous = queryClient.getQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!]);
        queryClient.setQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!], (old) => {
          const copy = { ...old }!;
          copy.name = body.data.name;
          return copy;
        });
        setOpen(false);
        return { previous, loadingToastId };
      },
      onError: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.error('Report name change failed');
        queryClient.setQueryData<ReportNestedSchema>(['project', project.id, 'reports', report.id!], context?.previous);
      },
      onSuccess: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        queryClient.invalidateQueries({
          queryKey: ['project', project.id, 'reports', 'filtered']
        });
        toast.success('Report name changed successfully');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['project', project.id, 'reports', report.id!]
        });
      }
    }
  });

  const handleUpdate = useCallback(
    function handleUpdate(data: RenameReportDialogFormValues) {
      if (data.name === report.name) {
        setError(
          'name',
          { message: "The name can't be the same as the old one.", type: 'validate' },
          { shouldFocus: true }
        );
        return;
      }

      const updatedData = {
        projectId: report.project!,
        reportId: report.id!,
        data: {
          name: data.name,
          project: report.project,
          report_entities: report.report_entities as unknown as number[]
        } satisfies ReportEditSchema
      };
      updateReport(updatedData);
    },
    [report.id, report.name, report.project, report.report_entities, setError, updateReport]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title="Change report name" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <form onSubmit={handleSubmit(handleUpdate)} id="update-report-name-form">
          <Input
            label="Enter report name"
            registerReturn={register('name', { required: 'Report Name is required' })}
            error={errors.name}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel} disabled={isUpdatingReport}>
          Cancel
        </Button>
        <Button variant="primary" size="xl" isSubmitButton form="update-report-name-form" loading={isUpdatingReport}>
          Save
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default RenameReportDialog;
