import { DEFAULT_LAST_EDITED_REPORTS_COUNT } from 'constants/reports';
import useActiveProject from 'contexts/project/projectContext';
import { useGetLastEditedReports } from 'lib/report/report';
import { memo } from 'react';
import ReportsGrid from './ReportGrid';

const LastEditedReports = memo(function LastEditedReports() {
  const { project } = useActiveProject();
  const { data: lastEditedReports, isFetching: isLastEditedReportsPending } = useGetLastEditedReports(
    project.id!,

    {
      num_last_edited_reports: DEFAULT_LAST_EDITED_REPORTS_COUNT
    },
    {
      query: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false
      }
    }
  );

  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-sm font-semibold">Last edited</span>
      <ReportsGrid data={lastEditedReports} isDataFetching={isLastEditedReportsPending} />
    </div>
  );
});

export default LastEditedReports;
