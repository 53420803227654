import Report from 'assets/report.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { useGetReports } from 'lib/report/report';
import SearchParamButton from 'modules/common/SearchParamButton';
import DummyDataBanner from 'modules/layouts/dataTablesLayout/components/DummyDataBanner';
import { memo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AllReports from './components/AllReports';
import FavoriteReports from './components/FavoriteReports';
import LastEditedReports from './components/LastEditedReports';
import ReportsPageHeader from './components/ReportsPageHeader';
import { reportTypeRoutes } from './constants';

type Display = 'list' | 'grid';

const ReportsPage = memo(function ReportsPage() {
  const { project } = useActiveProject();
  const [display, setDisplay] = useState<Display>('list');
  const [searchParams] = useSearchParams();

  const showFavoriteAndLastEdit = searchParams.get('ownership') === null && searchParams.get('reportType') === null;

  const { data: reports, isFetching: reportsFetching } = useGetReports(
    project.id!,
    {},
    {
      query: {
        queryKey: ['project', project.id!, 'reports'],
        staleTime: 1000 * 60 * 15 // 15 minutes
      }
    }
  );

  return (
    <>
      <ReportsPageHeader display={display} setDisplay={setDisplay} />
      <div className="mt-6 flex w-full flex-col">
        <DummyDataBanner
          missingNote={'Please connect data sources to create your first report.'}
          iteratingNote={'Please wait until the initial data sync is finished to create your first report.'}
        />
        {reportsFetching || reports!.objects!.length > 0 ? (
          <div className="flex w-full grow flex-col gap-10">
            <div className="flex flex-wrap items-center gap-3 pt-6">
              {reportTypeRoutes.map((route) => (
                <SearchParamButton border size="tiny" key={route.value} route={route} />
              ))}
            </div>
            {showFavoriteAndLastEdit && (
              <>
                <FavoriteReports />
                <LastEditedReports />
              </>
            )}
            <AllReports display={display} />
          </div>
        ) : (
          <div className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-50 py-16">
            <div className="flex flex-col items-center gap-3">
              <Report className="size-16 fill-gray-300" />
              <span className="text-sm font-medium text-gray-500">No reports.</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default ReportsPage;
