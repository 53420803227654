import { useQueryClient } from '@tanstack/react-query';
import Trash from 'assets/trash-remove.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { useDeleteReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface DeleteReportDialogProps extends DialogProps {
  reportName: string;
  reportId: string;
}

const DeleteReportDialog = memo(function DeleteReportDialog({
  open,
  setOpen,
  reportName,
  reportId
}: DeleteReportDialogProps) {
  const { project } = useActiveProject();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteReport, isPending: isDeleting } = useDeleteReport({
    mutation: {
      onSuccess: () => {
        toast.success('Report has been successfully deleted');
        // Also includes report elements
        queryClient.removeQueries({
          queryKey: ['project', project.id, 'reports', reportId]
        });
        queryClient.invalidateQueries({
          queryKey: ['project', project.id, 'reports', 'filtered']
        });
        if (location.pathname !== '/reports') {
          navigate('/reports');
        }
      }
    }
  });

  function handleDelete() {
    deleteReport({
      projectId: project.id!,
      reportId: reportId
    });
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title="Delete Report" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex h-36 items-center justify-center">
          <Trash className="size-16 fill-red-600" />
        </div>

        <p className="flex flex-col gap-2">
          <span className="text-sm">
            You are about to delete this report: <strong className="font-semibold">{reportName}</strong>
          </span>
          <span className="text-sm">Are you sure you want to proceed with deletion?</span>
        </p>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel} disabled={isDeleting}>
          Cancel
        </Button>
        <Button variant="red" size="lg" onClick={handleDelete} loading={isDeleting}>
          Delete
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default DeleteReportDialog;
