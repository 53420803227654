import { useQueryClient } from '@tanstack/react-query';
import useActiveProject from 'contexts/project/projectContext';
import { ReportNestedSchema, ReportSendScheduleCreateSchemaFrequency, ReportSendScheduleSchema } from 'lib/model';
import {
  useCreateReportSchedule,
  useDeleteReportSchedule,
  useSendReport,
  useUpdateReportSchedule
} from 'lib/report/report';
import Button from 'modules/common/Button';
import ButtonTabs from 'modules/common/ButtonTabs';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { DialogProps, ValueOption, Values } from 'utils/interfaces';
import ScheduleReportForm from './ScheduleReportForm';
import SendReportForm from './SendReportForm';
import { ScheduleReportFormValues, SendReportFormValues } from './interfaces';

interface SendReportDialogProps extends DialogProps {
  report: ReportNestedSchema;
  schedule?: ReportSendScheduleSchema;
}

function SendReportDialog({ open, setOpen, report, schedule }: SendReportDialogProps) {
  const { project } = useActiveProject();
  const [sendReportOption, setSendReportOption] = useState<Values<typeof sendReportOptions>>(
    schedule ? sendReportOptions[1] : sendReportOptions[0]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const queryClient = useQueryClient();
  const { mutate: sendReport, isPending: isSendingReport } = useSendReport({
    mutation: {
      onSuccess: () => {
        toast.success('Report has been successfully sent');
        queryClient.invalidateQueries({ queryKey: ['reportSchedule'] });
        setOpen(false);
      }
    }
  });
  const { mutate: createSchedule, isPending: isCreatingSchedule } = useCreateReportSchedule({
    mutation: {
      onSuccess: () => {
        toast.success('Schedule has been successfully created');
        queryClient.invalidateQueries({ queryKey: ['reportSchedule'] });
        setOpen(false);
      }
    }
  });
  const { mutate: updateSchedule, isPending: isUpdatingSchedule } = useUpdateReportSchedule({
    mutation: {
      onSuccess: () => {
        toast.success('Schedule has been successfully updated');
        queryClient.invalidateQueries({ queryKey: ['reportSchedule'] });
        setOpen(false);
      }
    }
  });
  const { mutate: deleteSchedule, isPending: isDeletingSchedule } = useDeleteReportSchedule({
    mutation: {
      onSuccess: () => {
        toast.success('Schedule has been successfully deleted');
        queryClient.setQueryData(['reportSchedule', report.id!], null);
      }
    }
  });

  function handleSendReport(data: SendReportFormValues) {
    sendReport({
      projectId: project.id!,
      reportId: report.id!,
      params: {
        emails: data.emails!.join(',')
      }
    });
  }

  function handleCreateSchedule(data: ScheduleReportFormValues) {
    createSchedule({
      projectId: project.id!,
      reportId: report.id!,
      data: {
        user_emails: data.emails as string[],
        frequency: data.scheduleFrequency.id as ReportSendScheduleCreateSchemaFrequency,
        time_of_day: data.scheduleTimeOfDay?.id as string,
        day_of_week: data.scheduleDayOfWeek?.id as number,
        day_of_month: data.scheduleDayOfMonth?.id as string,
        end_date: data.scheduleEndDate ?? undefined
      }
    });
  }

  function handleUpdateSchedule(data: ScheduleReportFormValues) {
    updateSchedule({
      projectId: project.id!,
      reportId: report.id!,
      data: {
        user_emails: data.emails as string[],
        frequency: data.scheduleFrequency.id as ReportSendScheduleCreateSchemaFrequency,
        time_of_day: data.scheduleTimeOfDay?.id as string,
        day_of_week: data.scheduleDayOfWeek?.id as number,
        day_of_month: data.scheduleDayOfMonth?.id as string,
        end_date: data.scheduleEndDate ?? undefined
      }
    });
  }

  function handleDeleteSchedule() {
    deleteSchedule({
      projectId: project.id!,
      reportId: report.id!
    });
  }

  return (
    <DialogBase title="Send Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <ButtonTabs
          options={sendReportOptions}
          selected={sendReportOption}
          setSelected={setSendReportOption}
          className="text-md font-semibold"
        />
        {sendReportOption.id === 'send' && <SendReportForm sendReport={handleSendReport} />}
        {sendReportOption.id === 'schedule' && (
          <ScheduleReportForm
            schedule={schedule}
            createSchedule={handleCreateSchedule}
            updateSchedule={handleUpdateSchedule}
            deleteSchedule={handleDeleteSchedule}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button
          variant="secondary"
          size="xl"
          onClick={handleCancel}
          disabled={isSendingReport || isCreatingSchedule || isUpdatingSchedule || isDeletingSchedule}
        >
          Cancel
        </Button>
        <Button
          size="xl"
          isSubmitButton
          form="send-report-form"
          loading={isSendingReport || isCreatingSchedule || isUpdatingSchedule || isDeletingSchedule}
        >
          {sendReportOption.id === 'send' ? 'Send Report' : schedule ? 'Update' : 'Create'}
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

const sendReportOptions: ValueOption[] = [
  {
    label: 'Send',
    id: 'send'
  },
  {
    label: 'Schedule',
    id: 'schedule'
  }
];

export default memo(SendReportDialog);
