import {
  ColumnDef,
  ColumnSort,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';
import GenericDisplayTable from 'modules/common/Table/GenericDisplayTable';
import { getReportElementColor } from 'modules/reports/colors';
import { memo, useMemo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import AggregationData from '../AggregationData';
import Player from '../Player';
import Team from '../Team';
import { BaseReportElementProps } from '../interfaces';

interface TableElementData {
  [key: string]: number | string | undefined;

  player_id: number;
  player_name: string;
  shirt_number: number;
  team_id: number;
  team_name: string;
  // group by fields
  season?: string;
  competition_id?: number;
  competition_name?: string;
  match_id?: number;
  home_team_name?: string;
  away_team_name?: string;
}

interface TableElementResult {
  result: TableElementData[];
}

const columnHelper = createColumnHelper<TableElementData>();

const TableElement = memo(function TableElement({ report, element }: BaseReportElementProps) {
  const { result: data } = element.entity_data as TableElementResult;

  const columns = useMemo<ColumnDef<TableElementData>[]>(() => {
    const attributeValues = element.attribute_values!;
    const isPlayer = attributeValues.attribute_type[0] === 'player';
    const isTeam = attributeValues.attribute_type[0] === 'team';

    const groupedBy: string[] = attributeValues!.group_by ?? [];
    const isMatch = true;

    let metrics: string[] = [];
    // Added checks for readability
    switch (true) {
      case isPlayer && isMatch:
        metrics = attributeValues.metrics_players_match;
        break;
      case isTeam && isMatch:
        metrics = attributeValues.metrics_teams_match;
        break;
    }

    let visualColumn;
    if (isPlayer) {
      visualColumn = columnHelper.accessor('player_name', {
        id: 'player_name',
        cell: (info) => {
          const { original } = info.row;
          return (
            <Player
              color={getReportElementColor(report, element, { teamId: original.team_id, playerId: original.player_id })}
              size="md"
              player_name={original.player_name}
              shirt_number={original.shirt_number}
            />
          );
        },
        header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">PLAYER</span>
      });
    } else {
      visualColumn = columnHelper.accessor('team_name', {
        id: 'team_name',
        cell: (info) => {
          const { original } = info.row;
          return (
            <Team
              color={getReportElementColor(report, element, { teamId: original.team_id })}
              size="md"
              team_name={original.team_name}
            />
          );
        },
        header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">TEAM</span>
      });
    }

    const groupAttributeColumns: ColumnDef<TableElementData>[] = [];
    if (groupedBy.includes('season')) {
      groupAttributeColumns.push(
        columnHelper.accessor('season', {
          id: 'season',
          cell: (info) => <span className="px-3 text-xs font-medium">{info.renderValue()}</span>,
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">SEASON</span>,
          meta: {
            align: 'center'
          }
        })
      );
    }
    if (groupedBy.includes('competition')) {
      groupAttributeColumns.push(
        columnHelper.accessor('competition_name', {
          id: 'competition_name',
          cell: (info) => (
            <span className="px-3 text-xs font-medium">{snakeCaseToWords(info.renderValue() as string)}</span>
          ),
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">COMPETITION</span>
        })
      );
    }
    if (groupedBy.includes('match')) {
      groupAttributeColumns.push(
        columnHelper.accessor('match_id', {
          id: 'match_id',
          cell: (info) => {
            const original = info.row.original;
            const name = `${original.home_team_name} vs ${original.away_team_name}`;
            return <span className="px-3 text-xs font-medium">{name}</span>;
          },
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">MATCH</span>
        })
      );
    }

    const sortingColumns: ColumnDef<TableElementData>[] = [];
    // Added column definitions for simpler sorting, they will be hidden
    if (isPlayer) {
      sortingColumns.push(
        columnHelper.accessor('team_name', {
          id: 'team_name'
        })
      );
      sortingColumns.push(
        columnHelper.accessor('shirt_number', {
          id: 'shirt_number'
        })
      );
    }

    return [
      visualColumn,
      ...sortingColumns,
      ...groupAttributeColumns,
      ...metrics.map((metric) => {
        return columnHelper.accessor(metric, {
          id: metric,
          cell: (info) => {
            let value = info.renderValue();
            if (typeof value === 'number' && value % 1 !== 0) {
              value = value.toFixed(2);
            }
            return <span className="px-3 text-xs font-medium">{value}</span>;
          },
          header: () => (
            <span className="px-3 text-tiny font-medium uppercase text-gray-500">{snakeCaseToWords(metric)}</span>
          ),
          meta: {
            align: String(data[0][metric]).length > 8 ? 'left' : 'center'
          }
        });
      })
    ];
  }, [data, element.attribute_values, report]);

  const { sorting, visibleColumns }: { sorting: ColumnSort[]; visibleColumns: VisibilityState } = useMemo(() => {
    const attributeValues = element.attribute_values!;
    const isPlayer = attributeValues.attribute_type[0] === 'player';
    const visibleColumns: VisibilityState = {};

    const sorting = [
      { id: 'season', desc: false },
      { id: 'team_name', desc: false }
    ];
    if (isPlayer) {
      sorting.push({ id: 'shirt_number', desc: false });
      visibleColumns.team_name = false;
      visibleColumns.shirt_number = false;
    }

    return { sorting, visibleColumns };
  }, [element.attribute_values]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    renderFallbackValue: '-',
    initialState: {
      sorting: sorting,
      columnVisibility: visibleColumns
    }
  });

  return (
    <div className="flex flex-col gap-6">
      {report.report_type !== 'match' && <AggregationData report={report} element={element} />}
      <GenericDisplayTable table={table} />
    </div>
  );
});

export default TableElement;
