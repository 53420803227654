import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { getInitials } from 'utils/helpers';

interface DataSourceTitleProps {
  name: string;
  size?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  imageSize?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  image?: string;
}

const DataSourceTitle = memo(function DataSourceTitle({
  name,
  size = 'sm',
  imageSize = 'md',
  image = undefined
}: DataSourceTitleProps) {
  return (
    <div className="flex grow items-center gap-3">
      <div
        className={twJoin(
          'flex items-center justify-center',
          imageSize === 'xl' && 'h-12 w-12 text-xl',
          imageSize === 'lg' && 'h-10 w-10 text-lg',
          imageSize === 'md' && 'h-8 w-8 text-md',
          imageSize === 'sm' && 'h-6 w-6 text-sm',
          imageSize === 'xs' && 'h-6 w-6 text-xs',
          imageSize === 'tiny' && 'h-5 w-5 text-tiny'
        )}
      >
        {image ? (
          <img className={'rounded-lg'} src={image} />
        ) : (
          <div className="brand-gradient flex h-full w-full items-center justify-center rounded-lg border border-gray-200 font-semibold">
            <span className="text-white">{getInitials(name)}</span>
          </div>
        )}
      </div>
      <span
        className={twJoin(
          'font-semibold text-gray-900',
          size === 'tiny' && 'text-tiny',
          size === 'xs' && 'text-xs',
          size === 'sm' && 'text-sm',
          size === 'md' && 'text-md',
          size === 'lg' && 'text-lg',
          size === 'xl' && 'text-xl'
        )}
      >
        {name}
      </span>
    </div>
  );
});

export default DataSourceTitle;
