import { ReportNestedSchema, UserAccountGetSchema } from 'lib/model';
import { Team } from 'utils/interfaces';
import {
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from './reportPage/components/reportCards/interfaces';

export function getOpposingTeams(report: ReportNestedSchema): [Team, Team | null] | null {
  if (report.report_type === 'match') {
    const generalData = report.general_data as MatchGeneralData;
    return [
      { team_id: generalData.home_team_id, team_name: generalData.home_team_name },
      { team_id: generalData.away_team_id, team_name: generalData.away_team_name }
    ];
  } else if (report.report_type === 'player') {
    const generalData = report.general_data as PlayerGeneralData;
    return [{ team_id: generalData.team_id, team_name: generalData.team_name }, null];
  } else if (report.report_type === 'player_comparison') {
    const generalData = report.general_data as PlayerComparisonGeneralData;
    return [
      { team_id: generalData.player1.team_id, team_name: generalData.player1.team_name },
      { team_id: generalData.player2.team_id, team_name: generalData.player2.team_name }
    ];
  } else if (report.report_type === 'team') {
    const generalData = report.general_data as TeamGeneralData;
    return [{ team_id: generalData.team_id, team_name: generalData.team_name }, null];
  } else if (report.report_type === 'team_comparison') {
    const generalData = report.general_data as TeamComparisonGeneralData;
    return [
      { team_id: generalData.team1.team_id, team_name: generalData.team1.team_name },
      { team_id: generalData.team2.team_id, team_name: generalData.team2.team_name }
    ];
  } else return null;
}

export function canUserEditReport(report: ReportNestedSchema, user: UserAccountGetSchema): boolean {
  const sharedUser = report.users?.find((x) => x.user_id === user.id);
  return sharedUser?.permission === 'edit';
}
