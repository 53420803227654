import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { LineupTeam } from './reportPage/components/elements/Lineup/interfaces';
import {
  LeagueGeneralData,
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from './reportPage/components/reportCards/interfaces';

type EntityColor = {
  playerId?: number;
  teamId?: number;
  competitionId?: number;

  homeOrAway?: 'home' | 'away';
  index?: number;
  isCompetition?: boolean;
};

const BASE_COLOR_OFFSET = 2;
const GOLDEN_RATIO_CONJUGATE = 0.618033988749895;

// TEMPORARY: hardcoded colors
const HARDCODED_COLORS = [
  '#097CF5',
  '#7E0174',
  '#25A946' // League color
  // '#f28d35',
  // '#D32C49',
  // '#3090CF',
  // '#e6f7ff',
  // '#0C8F47',
  // '#F7DB00',
  // '#C33C97'
];

/**
 * Converts an HSV color value to RGB.
 *
 * @param h - The hue, a number between 0 and 1.
 * @param s - The saturation, a number between 0 and 1.
 * @param v - The value (brightness), a number between 0 and 1.
 * @returns An object with the RGB representation, with each component (r, g, b) as an integer between 0 and 255.
 */
// Code from https://stackoverflow.com/questions/17242144/javascript-convert-hsb-hsv-color-to-rgb-accurately
function HSVtoRGB(h: number, s: number, v: number) {
  let r, g, b;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r! * 255),
    g: Math.round(g! * 255),
    b: Math.round(b! * 255)
  };
}

// UNUSED
export function getDefaultTeamColor(report: ReportNestedSchema, team_id: number) {
  if (report.report_type === 'match') {
    const generalData = report.general_data as MatchGeneralData;
    if (generalData.home_team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'player') {
    const generalData = report.general_data as PlayerGeneralData;
    if (generalData.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'player_comparison') {
    const generalData = report.general_data as PlayerComparisonGeneralData;
    if (generalData.player1.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'team') {
    const generalData = report.general_data as TeamGeneralData;
    if (generalData.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'team_comparison') {
    const generalData = report.general_data as TeamComparisonGeneralData;
    if (generalData.team1.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'scout') {
    const generalData = report.general_data as ScoutGeneralData;
    const player = generalData.players_filtered?.find((x) => x.team_id === team_id);
    if (player) {
      return getDefaultColor(generalData.players_filtered.findIndex((x) => x.team_id === team_id));
    } else {
      return getDefaultColor(generalData.players_manual.findIndex((x) => x.team_id === team_id));
    }
  }
  return getDefaultColor(0);
}

/**
 * Converts a hexadecimal color code to an RGB object.
 *
 * @param hex - The hexadecimal color code string (e.g., "#FFFFFF").
 * @returns An object with the properties `r`, `g`, and `b` representing the red, green, and blue color values respectively.
 */
function HexToRBG(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)!;
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
}

function getHardcodedColor(index: number, alpha: number = 1.0) {
  const i = index % HARDCODED_COLORS.length;
  const rgb = HexToRBG(HARDCODED_COLORS[i]);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function getDefaultColor(index: number, alpha: number = 1.0) {
  if (index < 2) {
    return getHardcodedColor(index, alpha);
  }

  const rgb = HSVtoRGB((index + BASE_COLOR_OFFSET) * GOLDEN_RATIO_CONJUGATE, 0.9, 0.8);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function getDefaultCompetitionColor(index: number, alpha: number = 0.65) {
  if (index < 1) {
    return getHardcodedColor(index + 2, alpha);
  }
  const rgb = HSVtoRGB((index + BASE_COLOR_OFFSET) * GOLDEN_RATIO_CONJUGATE, 0.8, 0.75);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${1.0})`;
}

function getElementColor(
  report: ReportNestedSchema,
  element: ReportElementSchema,
  entity: EntityColor,
  defaultColor: string
): string {
  return defaultColor;

  // Custom logic for specific elements
  const entityType = element.report_element_template_name!;

  switch (entityType) {
    case 'heatmap-event-data':
    case 'heatmap-tracking-data':
    case 'avg-positions-event-data':
    case 'avg-positions-tracking-data':
    case 'event-animation-tracking-data':
    case 'event-map':
    case 'lineups-pitch':
    case 'lineups-table':
    case 'standings':
    case 'comparison':
    case 'line-chart':
    case 'radar-chart':
    case 'scatter-chart':
    case 'table':
    case 'versus':
      break;
  }

  return defaultColor;
}

export function getMatchReportColor(generalData: MatchGeneralData, entity: EntityColor): string {
  if (entity.homeOrAway === 'home' || entity.teamId === generalData.home_team_id) {
    return generalData.home_team_color ?? getDefaultColor(0);
  } else if (entity.homeOrAway === 'away' || entity.teamId === generalData.away_team_id) {
    return generalData.away_team_color ?? getDefaultColor(1);
  } else {
    // If team not found, return different default color
    return getDefaultColor(2);
  }
}

export function getPlayerReportColor(generalData: PlayerGeneralData, entity: EntityColor) {
  if (generalData.player_id === entity.playerId) {
    return generalData.team_color ?? getDefaultColor(0);
  } else {
    // If player not found, return different default color
    return getDefaultColor(1);
  }
}

export function getPlayerComparisonReportColor(generalData: PlayerComparisonGeneralData, entity: EntityColor) {
  if (generalData.player1.player_id === entity.playerId) {
    return generalData.player1.team_color ?? getDefaultColor(0);
  } else if (generalData.player2.player_id === entity.playerId) {
    return generalData.player2.team_color ?? getDefaultColor(1);
  } else {
    // If player not found, return different default color
    return getDefaultColor(2);
  }
}

export function getScoutReportColor(generalData: ScoutGeneralData, entity: EntityColor) {
  // Players should have default color since we are not checking for team
  const player = generalData.players_manual?.find((x) => x.player_id === entity.playerId);
  if (player) {
    return getDefaultColor(generalData.players_manual.findIndex((x) => x.player_id === entity.playerId));
  } else {
    // This should always return a player since a player has to be in either manual or filtered
    const filteredPlayerIndex = generalData.players_filtered.findIndex((x) => x.player_id === entity.playerId);
    const index = filteredPlayerIndex + generalData.players_manual.length;

    return getDefaultColor(index);
  }
}

export function getTeamReportColor(generalData: TeamGeneralData, entity: EntityColor) {
  if (entity.teamId === generalData.team_id) {
    return generalData.color ?? getDefaultColor(0);
  } else {
    // If team not found, return different default color
    return getDefaultColor(1);
  }
}

export function getTeamComparisonReportColor(generalData: TeamComparisonGeneralData, entity: EntityColor) {
  if (generalData.team1.team_id === entity.teamId) {
    return generalData.team1.color ?? getDefaultColor(0);
  } else if (generalData.team2.team_id === entity.teamId) {
    return generalData.team2.color ?? getDefaultColor(1);
  } else {
    // If teams not found, return different default color
    return getDefaultColor(2);
  }
}

export function getLeagueReportColor(generalData: LeagueGeneralData, entity: EntityColor) {
  // Elements are various teams
  // Currently BE does not provide team colors
  // Should BE send team colors?
  // But teams in league depend on seasons?
  if (entity.isCompetition) {
    return getDefaultCompetitionColor(entity.index ?? 0);
  }
  const defaultColor: string = getDefaultColor(entity.teamId ?? 0);

  return defaultColor;
}

export function getReportColor(report: ReportNestedSchema, entity: EntityColor) {
  const reportType = report.report_type!;
  switch (reportType) {
    case 'match':
      return getMatchReportColor(report.general_data as MatchGeneralData, entity);
    case 'team':
      return getTeamReportColor(report.general_data as TeamGeneralData, entity);
    case 'team_comparison':
      return getTeamComparisonReportColor(report.general_data as TeamComparisonGeneralData, entity);
    case 'league':
      return getLeagueReportColor(report.general_data as LeagueGeneralData, entity);
    case 'player':
      return getPlayerReportColor(report.general_data as PlayerGeneralData, entity);
    case 'player_comparison':
      return getPlayerComparisonReportColor(report.general_data as PlayerComparisonGeneralData, entity);
    case 'scout':
      return getScoutReportColor(report.general_data as ScoutGeneralData, entity);
    default: {
      // Add check to ensure we captured all cases
      const _exhaustiveCheck: never = reportType;
      return _exhaustiveCheck;
    }
  }
}

export function getReportElementColor(
  report: ReportNestedSchema,
  element: ReportElementSchema,
  entity: EntityColor
): string {
  if (entity.isCompetition) {
    return getDefaultCompetitionColor(entity.index ?? 0);
  }

  const defaultColor = getReportColor(report, entity);
  return getElementColor(report, element, entity, defaultColor);
}

export function applyReportDefaultColors(report: ReportNestedSchema) {
  const reportType = report.report_type!;
  switch (reportType) {
    case 'match': {
      const generalData = report.general_data as MatchGeneralData;
      report.general_data!.home_team_color = getMatchReportColor(generalData, {
        teamId: generalData.home_team_id,
        homeOrAway: 'home'
      });
      report.general_data!.away_team_color = getMatchReportColor(generalData, {
        teamId: generalData.away_team_id,
        homeOrAway: 'away'
      });
      return report;
    }
    case 'team': {
      const generalData = report.general_data as TeamGeneralData;
      report.general_data!.color = getTeamReportColor(generalData, { teamId: generalData.team_id });
      return report;
    }
    case 'team_comparison': {
      const generalData = report.general_data as TeamComparisonGeneralData;
      report.general_data!.team1.color = getTeamComparisonReportColor(generalData, {
        teamId: generalData.team1.team_id
      });
      report.general_data!.team2.color = getTeamComparisonReportColor(generalData, {
        teamId: generalData.team2.team_id
      });
      return report;
    }
    case 'league': {
      // TODO:
      break;
    }
    case 'player': {
      const generalData = report.general_data as PlayerGeneralData;
      report.general_data!.team_color = getPlayerReportColor(generalData, { playerId: generalData.player_id });
      return report;
    }
    case 'player_comparison': {
      const generalData = report.general_data as PlayerComparisonGeneralData;
      report.general_data!.player1.team_color = getPlayerComparisonReportColor(generalData, {
        playerId: generalData.player1.player_id
      });
      report.general_data!.player2.team_color = getPlayerComparisonReportColor(generalData, {
        playerId: generalData.player2.player_id
      });
      return report;
    }
    case 'scout': {
      const generalData = report.general_data as ScoutGeneralData;
      report.general_data!.players_manual = generalData.players_manual.map((player, index) => ({
        ...player,
        team_color: getScoutReportColor(generalData, { playerId: player.player_id })
      }));
      report.general_data!.players_filtered = generalData.players_filtered.map((player, index) => ({
        ...player,
        team_color: getScoutReportColor(generalData, { playerId: player.player_id })
      }));
      return report;
    }
    default: {
      // Add check to ensure we captured all cases
      const _exhaustiveCheck: never = reportType;
      return _exhaustiveCheck;
    }
  }
}
